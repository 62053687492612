.dialog-widget-content {
    position : absolute;
    border-radius : 3px;
    box-shadow : 2px 8px 23px 3px rgb(0, 0, 0, 0.2);
    overflow : hidden;
    }
    .dialog-message {
    line-height : 1.5;
    box-sizing : border-box;
    }
    .dialog-close-button {
    cursor : pointer;
    position : absolute;
    margin-top : 15px;
    right : 15px;
    font-size : 15px;
    line-height : 1;
    }
    .dialog-prevent-scroll {
    overflow : hidden;
    max-height : 100vh;
    }
    .dialog-type-lightbox {
    position : fixed;
    height : 100%;
    width : 100%;
    bottom : 0;
    left : 0;
    background-color : rgb(0, 0, 0, 0.8);
    z-index : 9999;
    user-select : none;
    }
    .flatpickr-calendar {
    width : 280px;
    }
    .flatpickr-calendar .flatpickr-current-month span.cur-month {
    font-weight : 300;
    }
    .flatpickr-calendar .dayContainer {
    width : 280px;
    min-width : 280px;
    max-width : 280px;
    }
    .flatpickr-calendar .flatpickr-days {
    width : 280px;
    }
    .flatpickr-calendar .flatpickr-day {
    max-width : 37px;
    height : 37px;
    line-height : 37px;
    }
    .elementor-hidden {
    display : none;
    }
    .elementor-screen-only, .screen-reader-text, .screen-reader-text span, .ui-helper-hidden-accessible {
    position : absolute;
    top : -10000em;
    width : 1px;
    height : 1px;
    margin : -1px;
    padding : 0;
    overflow : hidden;
    clip : rect(0,0,0,0);
    border : 0;
    }
    .elementor-clearfix:after {
    content : "";
    display : block;
    clear : both;
    width : 0;
    height : 0;
    }
    .e-logo-wrapper {
    display : inline-block;
    padding : 0.75em;
    border-radius : 50%;
    line-height : 1;
    }
    .e-logo-wrapper i {
    font-size : 1em;
    }
    .elementor-aspect-ratio-219 .elementor-fit-aspect-ratio {
    padding-bottom : 42.8571%;
    }
    .elementor-aspect-ratio-169 .elementor-fit-aspect-ratio {
    padding-bottom : 56.25%;
    }
    .elementor-aspect-ratio-43 .elementor-fit-aspect-ratio {
    padding-bottom : 75%;
    }
    .elementor-aspect-ratio-32 .elementor-fit-aspect-ratio {
    padding-bottom : 66.6666%;
    }
    .elementor-aspect-ratio-11 .elementor-fit-aspect-ratio {
    padding-bottom : 100%;
    }
    .elementor-aspect-ratio-916 .elementor-fit-aspect-ratio {
    padding-bottom : 177.8%;
    }
    .elementor-fit-aspect-ratio {
    position : relative;
    height : 0;
    }
    .elementor-fit-aspect-ratio iframe {
    position : absolute;
    top : 0;
    left : 0;
    height : 100%;
    width : 100%;
    border : 0;
    background-color : #000;
    }
    .elementor-fit-aspect-ratio video {
    width : 100%;
    }
    .elementor *, .elementor :after, .elementor :before {
    box-sizing : border-box;
    }
    .elementor a {
    box-shadow : none;
    text-decoration : none;
    }
    .elementor hr {
    margin : 0;
    background-color : transparent;
    }
    .elementor img {
    height : auto;
    max-width : 100%;
    border : none;
    border-radius : 0;
    box-shadow : none;
    }
    .elementor .elementor-widget:not(.elementor-widget-text-editor):not(.elementor-widget-theme-post-content) figure {
    margin : 0;
    }
    .elementor embed, .elementor iframe, .elementor object, .elementor video {
    max-width : 100%;
    width : 100%;
    margin : 0;
    line-height : 1;
    border : none;
    }
    .elementor .elementor-background, .elementor .elementor-background-holder, .elementor .elementor-background-video-container {
    height : 100%;
    width : 100%;
    top : 0;
    left : 0;
    position : absolute;
    overflow : hidden;
    z-index : 0;
    direction : ltr;
    }
    .elementor .elementor-background-video-container {
    transition : opacity 1s;
    pointer-events : none;
    }
    .elementor .elementor-background-video-container.elementor-loading {
    opacity : 0;
    }
    .elementor .elementor-background-video-embed {
    max-width : none;
    }
    .elementor .elementor-background-video, .elementor .elementor-background-video-embed, .elementor .elementor-background-video-hosted {
    position : absolute;
    top : 50%;
    left : 50%;
    transform : translate(-50%,-50%);
    }
    .elementor .elementor-background-video {
    max-width : none;
    }
    .elementor .elementor-html5-video {
    object-fit : cover;
    }
    .elementor .elementor-background-overlay, .elementor .elementor-background-slideshow {
    height : 100%;
    width : 100%;
    top : 0;
    left : 0;
    position : absolute;
    }
    .elementor .elementor-background-slideshow {
    z-index : 0;
    }
    .elementor .elementor-background-slideshow__slide__image {
    width : 100%;
    height : 100%;
    background-position : 50%;
    background-size : cover;
    }
    .e-con-inner > .elementor-element.elementor-absolute, .e-con > .elementor-element.elementor-absolute, .elementor-widget-wrap > .elementor-element.elementor-absolute {
    position : absolute;
    }
    .e-con-inner > .elementor-element.elementor-fixed, .e-con > .elementor-element.elementor-fixed, .elementor-widget-wrap > .elementor-element.elementor-fixed {
    position : fixed;
    }
    .elementor-widget-wrap .elementor-element.elementor-widget__width-auto, .elementor-widget-wrap .elementor-element.elementor-widget__width-initial {
    max-width : 100%;
    }
    @media (max-width: 1024px) {
    .elementor-widget-wrap .elementor-element.elementor-widget-tablet__width-auto, .elementor-widget-wrap .elementor-element.elementor-widget-tablet__width-initial {
    max-width : 100%;
    }
    }
    @media (max-width: 767px) {
    .elementor-widget-wrap .elementor-element.elementor-widget-mobile__width-auto, .elementor-widget-wrap .elementor-element.elementor-widget-mobile__width-initial {
    max-width : 100%;
    }
    }
    .elementor-element {
    --flex-direction : initial;
    --flex-wrap : initial;
    --justify-content : initial;
    --align-items : initial;
    --align-content : initial;
    --gap : initial;
    --flex-basis : initial;
    --flex-grow : initial;
    --flex-shrink : initial;
    --order : initial;
    --align-self : initial;
    }
    .elementor-element.elementor-absolute, .elementor-element.elementor-fixed {
    z-index : 1;
    }
    .elementor-element:where(.e-con-full, .elementor-widget) {
    flex-direction : var(--flex-direction);
    flex-wrap : var(--flex-wrap);
    justify-content : var(--justify-content);
    align-items : var(--align-items);
    align-content : var(--align-content);
    gap : var(--gap);
    }
    .elementor-invisible {
    visibility : hidden;
    }
    .elementor-align-center {
    text-align : center;
    }
    .elementor-align-center .elementor-button {
    width : auto;
    }
    .elementor-align-right {
    text-align : right;
    }
    .elementor-align-right .elementor-button {
    width : auto;
    }
    .elementor-align-left {
    text-align : left;
    }
    .elementor-align-left .elementor-button {
    width : auto;
    }
    .elementor-align-justify .elementor-button {
    width : 100%;
    }
    .elementor-custom-embed-play {
    position : absolute;
    top : 50%;
    left : 50%;
    transform : translate(-50%,-50%);
    }
    .elementor-custom-embed-play i {
    font-size : 100px;
    color : #fff;
    text-shadow : 1px 0 6px rgb(0, 0, 0, 0.3);
    }
    .elementor-custom-embed-play svg {
    height : 100px;
    width : 100px;
    fill : #fff;
    filter : drop-shadow(1px 0 6px rgb(0, 0, 0, 0.3));
    }
    .elementor-custom-embed-play i, .elementor-custom-embed-play svg {
    opacity : 0.8;
    transition : all 0.5s;
    }
    .elementor-custom-embed-play.elementor-playing i {
    font-family : eicons;
    }
    .elementor-custom-embed-play.elementor-playing i:before {
    content : "\e8fb";
    }
    .elementor-custom-embed-play.elementor-playing i, .elementor-custom-embed-play.elementor-playing svg {
    animation : eicon-spin 2s linear infinite;
    }
    .elementor-tag {
    display : inline-flex;
    }
    .elementor-ken-burns {
    transition-property : transform;
    transition-duration : 10s;
    transition-timing-function : linear;
    }
    .elementor-ken-burns--out {
    transform : scale(1.3);
    }
    .elementor-ken-burns--active {
    transition-duration : 20s;
    }
    .elementor-ken-burns--active.elementor-ken-burns--out {
    transform : scale(1);
    }
    .elementor-ken-burns--active.elementor-ken-burns--in {
    transform : scale(1.3);
    }
    @media {
    .elementor-widescreen-align-center {
    text-align : center;
    }
    .elementor-widescreen-align-center .elementor-button {
    width : auto;
    }
    .elementor-widescreen-align-right {
    text-align : right;
    }
    .elementor-widescreen-align-right .elementor-button {
    width : auto;
    }
    .elementor-widescreen-align-left {
    text-align : left;
    }
    .elementor-widescreen-align-left .elementor-button {
    width : auto;
    }
    .elementor-widescreen-align-justify .elementor-button {
    width : 100%;
    }
    }
    @media {
    .elementor-laptop-align-center {
    text-align : center;
    }
    .elementor-laptop-align-center .elementor-button {
    width : auto;
    }
    .elementor-laptop-align-right {
    text-align : right;
    }
    .elementor-laptop-align-right .elementor-button {
    width : auto;
    }
    .elementor-laptop-align-left {
    text-align : left;
    }
    .elementor-laptop-align-left .elementor-button {
    width : auto;
    }
    .elementor-laptop-align-justify .elementor-button {
    width : 100%;
    }
    }
    @media {
    .elementor-tablet_extra-align-center {
    text-align : center;
    }
    .elementor-tablet_extra-align-center .elementor-button {
    width : auto;
    }
    .elementor-tablet_extra-align-right {
    text-align : right;
    }
    .elementor-tablet_extra-align-right .elementor-button {
    width : auto;
    }
    .elementor-tablet_extra-align-left {
    text-align : left;
    }
    .elementor-tablet_extra-align-left .elementor-button {
    width : auto;
    }
    .elementor-tablet_extra-align-justify .elementor-button {
    width : 100%;
    }
    }
    @media (max-width: 1024px) {
    .elementor-tablet-align-center {
    text-align : center;
    }
    .elementor-tablet-align-center .elementor-button {
    width : auto;
    }
    .elementor-tablet-align-right {
    text-align : right;
    }
    .elementor-tablet-align-right .elementor-button {
    width : auto;
    }
    .elementor-tablet-align-left {
    text-align : left;
    }
    .elementor-tablet-align-left .elementor-button {
    width : auto;
    }
    .elementor-tablet-align-justify .elementor-button {
    width : 100%;
    }
    }
    @media {
    .elementor-mobile_extra-align-center {
    text-align : center;
    }
    .elementor-mobile_extra-align-center .elementor-button {
    width : auto;
    }
    .elementor-mobile_extra-align-right {
    text-align : right;
    }
    .elementor-mobile_extra-align-right .elementor-button {
    width : auto;
    }
    .elementor-mobile_extra-align-left {
    text-align : left;
    }
    .elementor-mobile_extra-align-left .elementor-button {
    width : auto;
    }
    .elementor-mobile_extra-align-justify .elementor-button {
    width : 100%;
    }
    }
    @media (max-width: 767px) {
    .elementor-mobile-align-center {
    text-align : center;
    }
    .elementor-mobile-align-center .elementor-button {
    width : auto;
    }
    .elementor-mobile-align-right {
    text-align : right;
    }
    .elementor-mobile-align-right .elementor-button {
    width : auto;
    }
    .elementor-mobile-align-left {
    text-align : left;
    }
    .elementor-mobile-align-left .elementor-button {
    width : auto;
    }
    .elementor-mobile-align-justify .elementor-button {
    width : 100%;
    }
    }
    :root {
    --page-title-display : block;
    }
    .elementor-page-title, h1.entry-title {
    display : var(--page-title-display);
    }
    @keyframes eicon-spin {
    0% {
    transform : rotate(0deg);
    }
    to {
    transform : rotate(359deg);
    }
    }
    .eicon-animation-spin {
    animation : eicon-spin 2s linear infinite;
    }
    .elementor-section {
    position : relative;
    }
    .elementor-section .elementor-container {
    display : flex;
    margin-right : auto;
    margin-left : auto;
    position : relative;
    }
    @media (max-width: 1024px) {
    .elementor-section .elementor-container {
    flex-wrap : wrap;
    }
    }
    .elementor-section.elementor-section-boxed > .elementor-container {
    max-width : 1140px;
    }
    .elementor-section.elementor-section-stretched {
    position : relative;
    width : 100%;
    }
    .elementor-section.elementor-section-items-top > .elementor-container {
    align-items : flex-start;
    }
    .elementor-section.elementor-section-items-middle > .elementor-container {
    align-items : center;
    }
    .elementor-section.elementor-section-items-bottom > .elementor-container {
    align-items : flex-end;
    }
    @media (min-width: 768px) {
    .elementor-section.elementor-section-height-full {
    height : 100vh;
    }
    .elementor-section.elementor-section-height-full > .elementor-container {
    height : 100%;
    }
    }
    .elementor-bc-flex-widget .elementor-section-content-top > .elementor-container > .elementor-column > .elementor-widget-wrap {
    align-items : flex-start;
    }
    .elementor-bc-flex-widget .elementor-section-content-middle > .elementor-container > .elementor-column > .elementor-widget-wrap {
    align-items : center;
    }
    .elementor-bc-flex-widget .elementor-section-content-bottom > .elementor-container > .elementor-column > .elementor-widget-wrap {
    align-items : flex-end;
    }
    .elementor-row {
    width : 100%;
    display : flex;
    }
    @media (max-width: 1024px) {
    .elementor-row {
    flex-wrap : wrap;
    }
    }
    .elementor-widget-wrap {
    position : relative;
    width : 100%;
    flex-wrap : wrap;
    align-content : flex-start;
    }
    .elementor:not(.elementor-bc-flex-widget) .elementor-widget-wrap {
    display : flex;
    }
    .elementor-widget-wrap > .elementor-element {
    width : 100%;
    }
    .elementor-widget-wrap.e-swiper-container {
    width : calc(100% - (var(--e-column-margin-left, 0) + var(--e-column-margin-right, 0)));
    }
    .elementor-widget {
    position : relative;
    }
    .elementor-widget:not(:last-child) {
    margin-bottom : 20px;
    }
    .elementor-widget:not(:last-child).elementor-absolute, .elementor-widget:not(:last-child).elementor-widget__width-auto, .elementor-widget:not(:last-child).elementor-widget__width-initial {
    margin-bottom : 0;
    }
    .elementor-column {
    position : relative;
    min-height : 1px;
    display : flex;
    }
    .elementor-column-wrap {
    width : 100%;
    position : relative;
    display : flex;
    }
    .elementor-column-gap-narrow > .elementor-column > .elementor-element-populated {
    padding : 5px;
    }
    .elementor-column-gap-default > .elementor-column > .elementor-element-populated {
    padding : 10px;
    }
    .elementor-column-gap-extended > .elementor-column > .elementor-element-populated {
    padding : 15px;
    }
    .elementor-column-gap-wide > .elementor-column > .elementor-element-populated {
    padding : 20px;
    }
    .elementor-column-gap-wider > .elementor-column > .elementor-element-populated {
    padding : 30px;
    }
    .elementor-inner-section .elementor-column-gap-no .elementor-element-populated {
    padding : 0;
    }
    @media (min-width: 768px) {
    .elementor-column.elementor-col-10, .elementor-column[data-col="10"] {
    width : 10%;
    }
    .elementor-column.elementor-col-11, .elementor-column[data-col="11"] {
    width : 11.111%;
    }
    .elementor-column.elementor-col-12, .elementor-column[data-col="12"] {
    width : 12.5%;
    }
    .elementor-column.elementor-col-14, .elementor-column[data-col="14"] {
    width : 14.285%;
    }
    .elementor-column.elementor-col-16, .elementor-column[data-col="16"] {
    width : 16.666%;
    }
    .elementor-column.elementor-col-20, .elementor-column[data-col="20"] {
    width : 20%;
    }
    .elementor-column.elementor-col-25, .elementor-column[data-col="25"] {
    width : 25%;
    }
    .elementor-column.elementor-col-30, .elementor-column[data-col="30"] {
    width : 30%;
    }
    .elementor-column.elementor-col-33, .elementor-column[data-col="33"] {
    width : 33.333%;
    }
    .elementor-column.elementor-col-40, .elementor-column[data-col="40"] {
    width : 40%;
    }
    .elementor-column.elementor-col-50, .elementor-column[data-col="50"] {
    width : 50%;
    }
    .elementor-column.elementor-col-60, .elementor-column[data-col="60"] {
    width : 60%;
    }
    .elementor-column.elementor-col-66, .elementor-column[data-col="66"] {
    width : 66.666%;
    }
    .elementor-column.elementor-col-70, .elementor-column[data-col="70"] {
    width : 70%;
    }
    .elementor-column.elementor-col-75, .elementor-column[data-col="75"] {
    width : 75%;
    }
    .elementor-column.elementor-col-80, .elementor-column[data-col="80"] {
    width : 80%;
    }
    .elementor-column.elementor-col-83, .elementor-column[data-col="83"] {
    width : 83.333%;
    }
    .elementor-column.elementor-col-90, .elementor-column[data-col="90"] {
    width : 90%;
    }
    .elementor-column.elementor-col-100, .elementor-column[data-col="100"] {
    width : 100%;
    }
    }
    @media (max-width: 479px) {
    .elementor-column.elementor-xs-10 {
    width : 10%;
    }
    .elementor-column.elementor-xs-11 {
    width : 11.111%;
    }
    .elementor-column.elementor-xs-12 {
    width : 12.5%;
    }
    .elementor-column.elementor-xs-14 {
    width : 14.285%;
    }
    .elementor-column.elementor-xs-16 {
    width : 16.666%;
    }
    .elementor-column.elementor-xs-20 {
    width : 20%;
    }
    .elementor-column.elementor-xs-25 {
    width : 25%;
    }
    .elementor-column.elementor-xs-30 {
    width : 30%;
    }
    .elementor-column.elementor-xs-33 {
    width : 33.333%;
    }
    .elementor-column.elementor-xs-40 {
    width : 40%;
    }
    .elementor-column.elementor-xs-50 {
    width : 50%;
    }
    .elementor-column.elementor-xs-60 {
    width : 60%;
    }
    .elementor-column.elementor-xs-66 {
    width : 66.666%;
    }
    .elementor-column.elementor-xs-70 {
    width : 70%;
    }
    .elementor-column.elementor-xs-75 {
    width : 75%;
    }
    .elementor-column.elementor-xs-80 {
    width : 80%;
    }
    .elementor-column.elementor-xs-83 {
    width : 83.333%;
    }
    .elementor-column.elementor-xs-90 {
    width : 90%;
    }
    .elementor-column.elementor-xs-100 {
    width : 100%;
    }
    }
    @media (max-width: 767px) {
    .elementor-column.elementor-sm-10 {
    width : 10%;
    }
    .elementor-column.elementor-sm-11 {
    width : 11.111%;
    }
    .elementor-column.elementor-sm-12 {
    width : 12.5%;
    }
    .elementor-column.elementor-sm-14 {
    width : 14.285%;
    }
    .elementor-column.elementor-sm-16 {
    width : 16.666%;
    }
    .elementor-column.elementor-sm-20 {
    width : 20%;
    }
    .elementor-column.elementor-sm-25 {
    width : 25%;
    }
    .elementor-column.elementor-sm-30 {
    width : 30%;
    }
    .elementor-column.elementor-sm-33 {
    width : 33.333%;
    }
    .elementor-column.elementor-sm-40 {
    width : 40%;
    }
    .elementor-column.elementor-sm-50 {
    width : 50%;
    }
    .elementor-column.elementor-sm-60 {
    width : 60%;
    }
    .elementor-column.elementor-sm-66 {
    width : 66.666%;
    }
    .elementor-column.elementor-sm-70 {
    width : 70%;
    }
    .elementor-column.elementor-sm-75 {
    width : 75%;
    }
    .elementor-column.elementor-sm-80 {
    width : 80%;
    }
    .elementor-column.elementor-sm-83 {
    width : 83.333%;
    }
    .elementor-column.elementor-sm-90 {
    width : 90%;
    }
    .elementor-column.elementor-sm-100 {
    width : 100%;
    }
    }
    @media (min-width: 768px) and (max-width: 1024px) {
    .elementor-column.elementor-md-10 {
    width : 10%;
    }
    .elementor-column.elementor-md-11 {
    width : 11.111%;
    }
    .elementor-column.elementor-md-12 {
    width : 12.5%;
    }
    .elementor-column.elementor-md-14 {
    width : 14.285%;
    }
    .elementor-column.elementor-md-16 {
    width : 16.666%;
    }
    .elementor-column.elementor-md-20 {
    width : 20%;
    }
    .elementor-column.elementor-md-25 {
    width : 25%;
    }
    .elementor-column.elementor-md-30 {
    width : 30%;
    }
    .elementor-column.elementor-md-33 {
    width : 33.333%;
    }
    .elementor-column.elementor-md-40 {
    width : 40%;
    }
    .elementor-column.elementor-md-50 {
    width : 50%;
    }
    .elementor-column.elementor-md-60 {
    width : 60%;
    }
    .elementor-column.elementor-md-66 {
    width : 66.666%;
    }
    .elementor-column.elementor-md-70 {
    width : 70%;
    }
    .elementor-column.elementor-md-75 {
    width : 75%;
    }
    .elementor-column.elementor-md-80 {
    width : 80%;
    }
    .elementor-column.elementor-md-83 {
    width : 83.333%;
    }
    .elementor-column.elementor-md-90 {
    width : 90%;
    }
    .elementor-column.elementor-md-100 {
    width : 100%;
    }
    }
    @media {
    .elementor-reverse-widescreen > .elementor-container > :first-child {
    order : 10;
    }
    .elementor-reverse-widescreen > .elementor-container > :nth-child(2) {
    order : 9;
    }
    .elementor-reverse-widescreen > .elementor-container > :nth-child(3) {
    order : 8;
    }
    .elementor-reverse-widescreen > .elementor-container > :nth-child(4) {
    order : 7;
    }
    .elementor-reverse-widescreen > .elementor-container > :nth-child(5) {
    order : 6;
    }
    .elementor-reverse-widescreen > .elementor-container > :nth-child(6) {
    order : 5;
    }
    .elementor-reverse-widescreen > .elementor-container > :nth-child(7) {
    order : 4;
    }
    .elementor-reverse-widescreen > .elementor-container > :nth-child(8) {
    order : 3;
    }
    .elementor-reverse-widescreen > .elementor-container > :nth-child(9) {
    order : 2;
    }
    .elementor-reverse-widescreen > .elementor-container > :nth-child(10) {
    order : 1;
    }
    }
    @media (min-width: 1025px) {
    .elementor-reverse-laptop > .elementor-container > :first-child {
    order : 10;
    }
    .elementor-reverse-laptop > .elementor-container > :nth-child(2) {
    order : 9;
    }
    .elementor-reverse-laptop > .elementor-container > :nth-child(3) {
    order : 8;
    }
    .elementor-reverse-laptop > .elementor-container > :nth-child(4) {
    order : 7;
    }
    .elementor-reverse-laptop > .elementor-container > :nth-child(5) {
    order : 6;
    }
    .elementor-reverse-laptop > .elementor-container > :nth-child(6) {
    order : 5;
    }
    .elementor-reverse-laptop > .elementor-container > :nth-child(7) {
    order : 4;
    }
    .elementor-reverse-laptop > .elementor-container > :nth-child(8) {
    order : 3;
    }
    .elementor-reverse-laptop > .elementor-container > :nth-child(9) {
    order : 2;
    }
    .elementor-reverse-laptop > .elementor-container > :nth-child(10) {
    order : 1;
    }
    }
    @media {
    .elementor-reverse-laptop > .elementor-container > :first-child {
    order : 10;
    }
    .elementor-reverse-laptop > .elementor-container > :nth-child(2) {
    order : 9;
    }
    .elementor-reverse-laptop > .elementor-container > :nth-child(3) {
    order : 8;
    }
    .elementor-reverse-laptop > .elementor-container > :nth-child(4) {
    order : 7;
    }
    .elementor-reverse-laptop > .elementor-container > :nth-child(5) {
    order : 6;
    }
    .elementor-reverse-laptop > .elementor-container > :nth-child(6) {
    order : 5;
    }
    .elementor-reverse-laptop > .elementor-container > :nth-child(7) {
    order : 4;
    }
    .elementor-reverse-laptop > .elementor-container > :nth-child(8) {
    order : 3;
    }
    .elementor-reverse-laptop > .elementor-container > :nth-child(9) {
    order : 2;
    }
    .elementor-reverse-laptop > .elementor-container > :nth-child(10) {
    order : 1;
    }
    }
    @media (min-width: 1025px) {
    .elementor-reverse-laptop > .elementor-container > :first-child, .elementor-reverse-laptop > .elementor-container > :nth-child(2), .elementor-reverse-laptop > .elementor-container > :nth-child(3), .elementor-reverse-laptop > .elementor-container > :nth-child(4), .elementor-reverse-laptop > .elementor-container > :nth-child(5), .elementor-reverse-laptop > .elementor-container > :nth-child(6), .elementor-reverse-laptop > .elementor-container > :nth-child(7), .elementor-reverse-laptop > .elementor-container > :nth-child(8), .elementor-reverse-laptop > .elementor-container > :nth-child(9), .elementor-reverse-laptop > .elementor-container > :nth-child(10) {
    order : 0;
    }
    .elementor-reverse-tablet_extra > .elementor-container > :first-child {
    order : 10;
    }
    .elementor-reverse-tablet_extra > .elementor-container > :nth-child(2) {
    order : 9;
    }
    .elementor-reverse-tablet_extra > .elementor-container > :nth-child(3) {
    order : 8;
    }
    .elementor-reverse-tablet_extra > .elementor-container > :nth-child(4) {
    order : 7;
    }
    .elementor-reverse-tablet_extra > .elementor-container > :nth-child(5) {
    order : 6;
    }
    .elementor-reverse-tablet_extra > .elementor-container > :nth-child(6) {
    order : 5;
    }
    .elementor-reverse-tablet_extra > .elementor-container > :nth-child(7) {
    order : 4;
    }
    .elementor-reverse-tablet_extra > .elementor-container > :nth-child(8) {
    order : 3;
    }
    .elementor-reverse-tablet_extra > .elementor-container > :nth-child(9) {
    order : 2;
    }
    .elementor-reverse-tablet_extra > .elementor-container > :nth-child(10) {
    order : 1;
    }
    }
    @media (min-width: 768px) and (max-width: 1024px) {
    .elementor-reverse-tablet > .elementor-container > :first-child {
    order : 10;
    }
    .elementor-reverse-tablet > .elementor-container > :nth-child(2) {
    order : 9;
    }
    .elementor-reverse-tablet > .elementor-container > :nth-child(3) {
    order : 8;
    }
    .elementor-reverse-tablet > .elementor-container > :nth-child(4) {
    order : 7;
    }
    .elementor-reverse-tablet > .elementor-container > :nth-child(5) {
    order : 6;
    }
    .elementor-reverse-tablet > .elementor-container > :nth-child(6) {
    order : 5;
    }
    .elementor-reverse-tablet > .elementor-container > :nth-child(7) {
    order : 4;
    }
    .elementor-reverse-tablet > .elementor-container > :nth-child(8) {
    order : 3;
    }
    .elementor-reverse-tablet > .elementor-container > :nth-child(9) {
    order : 2;
    }
    .elementor-reverse-tablet > .elementor-container > :nth-child(10) {
    order : 1;
    }
    }
    @media (min-width: 768px) {
    .elementor-reverse-tablet > .elementor-container > :first-child, .elementor-reverse-tablet > .elementor-container > :nth-child(2), .elementor-reverse-tablet > .elementor-container > :nth-child(3), .elementor-reverse-tablet > .elementor-container > :nth-child(4), .elementor-reverse-tablet > .elementor-container > :nth-child(5), .elementor-reverse-tablet > .elementor-container > :nth-child(6), .elementor-reverse-tablet > .elementor-container > :nth-child(7), .elementor-reverse-tablet > .elementor-container > :nth-child(8), .elementor-reverse-tablet > .elementor-container > :nth-child(9), .elementor-reverse-tablet > .elementor-container > :nth-child(10) {
    order : 0;
    }
    .elementor-reverse-mobile_extra > .elementor-container > :first-child {
    order : 10;
    }
    .elementor-reverse-mobile_extra > .elementor-container > :nth-child(2) {
    order : 9;
    }
    .elementor-reverse-mobile_extra > .elementor-container > :nth-child(3) {
    order : 8;
    }
    .elementor-reverse-mobile_extra > .elementor-container > :nth-child(4) {
    order : 7;
    }
    .elementor-reverse-mobile_extra > .elementor-container > :nth-child(5) {
    order : 6;
    }
    .elementor-reverse-mobile_extra > .elementor-container > :nth-child(6) {
    order : 5;
    }
    .elementor-reverse-mobile_extra > .elementor-container > :nth-child(7) {
    order : 4;
    }
    .elementor-reverse-mobile_extra > .elementor-container > :nth-child(8) {
    order : 3;
    }
    .elementor-reverse-mobile_extra > .elementor-container > :nth-child(9) {
    order : 2;
    }
    .elementor-reverse-mobile_extra > .elementor-container > :nth-child(10) {
    order : 1;
    }
    }
    @media (max-width: 767px) {
    .elementor-reverse-mobile > .elementor-container > :first-child {
    order : 10;
    }
    .elementor-reverse-mobile > .elementor-container > :nth-child(2) {
    order : 9;
    }
    .elementor-reverse-mobile > .elementor-container > :nth-child(3) {
    order : 8;
    }
    .elementor-reverse-mobile > .elementor-container > :nth-child(4) {
    order : 7;
    }
    .elementor-reverse-mobile > .elementor-container > :nth-child(5) {
    order : 6;
    }
    .elementor-reverse-mobile > .elementor-container > :nth-child(6) {
    order : 5;
    }
    .elementor-reverse-mobile > .elementor-container > :nth-child(7) {
    order : 4;
    }
    .elementor-reverse-mobile > .elementor-container > :nth-child(8) {
    order : 3;
    }
    .elementor-reverse-mobile > .elementor-container > :nth-child(9) {
    order : 2;
    }
    .elementor-reverse-mobile > .elementor-container > :nth-child(10) {
    order : 1;
    }
    .elementor-column {
    width : 100%;
    }
    }
    ul.elementor-icon-list-items.elementor-inline-items {
    display : flex;
    flex-wrap : wrap;
    }
    ul.elementor-icon-list-items.elementor-inline-items .elementor-inline-item {
    word-break : break-word;
    }
    .elementor-grid {
    display : grid;
    }
    .elementor-grid .elementor-grid-item {
    min-width : 0;
    }
    .elementor-grid-0 .elementor-grid {
    display : inline-block;
    width : 100%;
    }
    .elementor-grid-0 .elementor-grid .elementor-grid-item {
    display : inline-block;
    word-break : break-word;
    }
    .elementor-grid-1 .elementor-grid {
    grid-template-columns : repeat(1,1fr);
    }
    .elementor-grid-2 .elementor-grid {
    grid-template-columns : repeat(2,1fr);
    }
    .elementor-grid-3 .elementor-grid {
    grid-template-columns : repeat(3,1fr);
    }
    .elementor-grid-4 .elementor-grid {
    grid-template-columns : repeat(4,1fr);
    }
    .elementor-grid-5 .elementor-grid {
    grid-template-columns : repeat(5,1fr);
    }
    .elementor-grid-6 .elementor-grid {
    grid-template-columns : repeat(6,1fr);
    }
    .elementor-grid-7 .elementor-grid {
    grid-template-columns : repeat(7,1fr);
    }
    .elementor-grid-8 .elementor-grid {
    grid-template-columns : repeat(8,1fr);
    }
    .elementor-grid-9 .elementor-grid {
    grid-template-columns : repeat(9,1fr);
    }
    .elementor-grid-10 .elementor-grid {
    grid-template-columns : repeat(10,1fr);
    }
    .elementor-grid-11 .elementor-grid {
    grid-template-columns : repeat(11,1fr);
    }
    .elementor-grid-12 .elementor-grid {
    grid-template-columns : repeat(12,1fr);
    }
    @media {
    .elementor-grid-widescreen-0 .elementor-grid {
    display : inline-block;
    width : 100%;
    }
    .elementor-grid-widescreen-0 .elementor-grid .elementor-grid-item {
    display : inline-block;
    word-break : break-word;
    }
    .elementor-grid-widescreen-1 .elementor-grid {
    grid-template-columns : repeat(1,1fr);
    }
    .elementor-grid-widescreen-2 .elementor-grid {
    grid-template-columns : repeat(2,1fr);
    }
    .elementor-grid-widescreen-3 .elementor-grid {
    grid-template-columns : repeat(3,1fr);
    }
    .elementor-grid-widescreen-4 .elementor-grid {
    grid-template-columns : repeat(4,1fr);
    }
    .elementor-grid-widescreen-5 .elementor-grid {
    grid-template-columns : repeat(5,1fr);
    }
    .elementor-grid-widescreen-6 .elementor-grid {
    grid-template-columns : repeat(6,1fr);
    }
    .elementor-grid-widescreen-7 .elementor-grid {
    grid-template-columns : repeat(7,1fr);
    }
    .elementor-grid-widescreen-8 .elementor-grid {
    grid-template-columns : repeat(8,1fr);
    }
    .elementor-grid-widescreen-9 .elementor-grid {
    grid-template-columns : repeat(9,1fr);
    }
    .elementor-grid-widescreen-10 .elementor-grid {
    grid-template-columns : repeat(10,1fr);
    }
    .elementor-grid-widescreen-11 .elementor-grid {
    grid-template-columns : repeat(11,1fr);
    }
    .elementor-grid-widescreen-12 .elementor-grid {
    grid-template-columns : repeat(12,1fr);
    }
    }
    @media {
    .elementor-grid-laptop-0 .elementor-grid {
    display : inline-block;
    width : 100%;
    }
    .elementor-grid-laptop-0 .elementor-grid .elementor-grid-item {
    display : inline-block;
    word-break : break-word;
    }
    .elementor-grid-laptop-1 .elementor-grid {
    grid-template-columns : repeat(1,1fr);
    }
    .elementor-grid-laptop-2 .elementor-grid {
    grid-template-columns : repeat(2,1fr);
    }
    .elementor-grid-laptop-3 .elementor-grid {
    grid-template-columns : repeat(3,1fr);
    }
    .elementor-grid-laptop-4 .elementor-grid {
    grid-template-columns : repeat(4,1fr);
    }
    .elementor-grid-laptop-5 .elementor-grid {
    grid-template-columns : repeat(5,1fr);
    }
    .elementor-grid-laptop-6 .elementor-grid {
    grid-template-columns : repeat(6,1fr);
    }
    .elementor-grid-laptop-7 .elementor-grid {
    grid-template-columns : repeat(7,1fr);
    }
    .elementor-grid-laptop-8 .elementor-grid {
    grid-template-columns : repeat(8,1fr);
    }
    .elementor-grid-laptop-9 .elementor-grid {
    grid-template-columns : repeat(9,1fr);
    }
    .elementor-grid-laptop-10 .elementor-grid {
    grid-template-columns : repeat(10,1fr);
    }
    .elementor-grid-laptop-11 .elementor-grid {
    grid-template-columns : repeat(11,1fr);
    }
    .elementor-grid-laptop-12 .elementor-grid {
    grid-template-columns : repeat(12,1fr);
    }
    }
    @media {
    .elementor-grid-tablet_extra-0 .elementor-grid {
    display : inline-block;
    width : 100%;
    }
    .elementor-grid-tablet_extra-0 .elementor-grid .elementor-grid-item {
    display : inline-block;
    word-break : break-word;
    }
    .elementor-grid-tablet_extra-1 .elementor-grid {
    grid-template-columns : repeat(1,1fr);
    }
    .elementor-grid-tablet_extra-2 .elementor-grid {
    grid-template-columns : repeat(2,1fr);
    }
    .elementor-grid-tablet_extra-3 .elementor-grid {
    grid-template-columns : repeat(3,1fr);
    }
    .elementor-grid-tablet_extra-4 .elementor-grid {
    grid-template-columns : repeat(4,1fr);
    }
    .elementor-grid-tablet_extra-5 .elementor-grid {
    grid-template-columns : repeat(5,1fr);
    }
    .elementor-grid-tablet_extra-6 .elementor-grid {
    grid-template-columns : repeat(6,1fr);
    }
    .elementor-grid-tablet_extra-7 .elementor-grid {
    grid-template-columns : repeat(7,1fr);
    }
    .elementor-grid-tablet_extra-8 .elementor-grid {
    grid-template-columns : repeat(8,1fr);
    }
    .elementor-grid-tablet_extra-9 .elementor-grid {
    grid-template-columns : repeat(9,1fr);
    }
    .elementor-grid-tablet_extra-10 .elementor-grid {
    grid-template-columns : repeat(10,1fr);
    }
    .elementor-grid-tablet_extra-11 .elementor-grid {
    grid-template-columns : repeat(11,1fr);
    }
    .elementor-grid-tablet_extra-12 .elementor-grid {
    grid-template-columns : repeat(12,1fr);
    }
    }
    @media (max-width: 1024px) {
    .elementor-grid-tablet-0 .elementor-grid {
    display : inline-block;
    width : 100%;
    }
    .elementor-grid-tablet-0 .elementor-grid .elementor-grid-item {
    display : inline-block;
    word-break : break-word;
    }
    .elementor-grid-tablet-1 .elementor-grid {
    grid-template-columns : repeat(1,1fr);
    }
    .elementor-grid-tablet-2 .elementor-grid {
    grid-template-columns : repeat(2,1fr);
    }
    .elementor-grid-tablet-3 .elementor-grid {
    grid-template-columns : repeat(3,1fr);
    }
    .elementor-grid-tablet-4 .elementor-grid {
    grid-template-columns : repeat(4,1fr);
    }
    .elementor-grid-tablet-5 .elementor-grid {
    grid-template-columns : repeat(5,1fr);
    }
    .elementor-grid-tablet-6 .elementor-grid {
    grid-template-columns : repeat(6,1fr);
    }
    .elementor-grid-tablet-7 .elementor-grid {
    grid-template-columns : repeat(7,1fr);
    }
    .elementor-grid-tablet-8 .elementor-grid {
    grid-template-columns : repeat(8,1fr);
    }
    .elementor-grid-tablet-9 .elementor-grid {
    grid-template-columns : repeat(9,1fr);
    }
    .elementor-grid-tablet-10 .elementor-grid {
    grid-template-columns : repeat(10,1fr);
    }
    .elementor-grid-tablet-11 .elementor-grid {
    grid-template-columns : repeat(11,1fr);
    }
    .elementor-grid-tablet-12 .elementor-grid {
    grid-template-columns : repeat(12,1fr);
    }
    }
    @media {
    .elementor-grid-mobile_extra-0 .elementor-grid {
    display : inline-block;
    width : 100%;
    }
    .elementor-grid-mobile_extra-0 .elementor-grid .elementor-grid-item {
    display : inline-block;
    word-break : break-word;
    }
    .elementor-grid-mobile_extra-1 .elementor-grid {
    grid-template-columns : repeat(1,1fr);
    }
    .elementor-grid-mobile_extra-2 .elementor-grid {
    grid-template-columns : repeat(2,1fr);
    }
    .elementor-grid-mobile_extra-3 .elementor-grid {
    grid-template-columns : repeat(3,1fr);
    }
    .elementor-grid-mobile_extra-4 .elementor-grid {
    grid-template-columns : repeat(4,1fr);
    }
    .elementor-grid-mobile_extra-5 .elementor-grid {
    grid-template-columns : repeat(5,1fr);
    }
    .elementor-grid-mobile_extra-6 .elementor-grid {
    grid-template-columns : repeat(6,1fr);
    }
    .elementor-grid-mobile_extra-7 .elementor-grid {
    grid-template-columns : repeat(7,1fr);
    }
    .elementor-grid-mobile_extra-8 .elementor-grid {
    grid-template-columns : repeat(8,1fr);
    }
    .elementor-grid-mobile_extra-9 .elementor-grid {
    grid-template-columns : repeat(9,1fr);
    }
    .elementor-grid-mobile_extra-10 .elementor-grid {
    grid-template-columns : repeat(10,1fr);
    }
    .elementor-grid-mobile_extra-11 .elementor-grid {
    grid-template-columns : repeat(11,1fr);
    }
    .elementor-grid-mobile_extra-12 .elementor-grid {
    grid-template-columns : repeat(12,1fr);
    }
    }
    @media (max-width: 767px) {
    .elementor-grid-mobile-0 .elementor-grid {
    display : inline-block;
    width : 100%;
    }
    .elementor-grid-mobile-0 .elementor-grid .elementor-grid-item {
    display : inline-block;
    word-break : break-word;
    }
    .elementor-grid-mobile-1 .elementor-grid {
    grid-template-columns : repeat(1,1fr);
    }
    .elementor-grid-mobile-2 .elementor-grid {
    grid-template-columns : repeat(2,1fr);
    }
    .elementor-grid-mobile-3 .elementor-grid {
    grid-template-columns : repeat(3,1fr);
    }
    .elementor-grid-mobile-4 .elementor-grid {
    grid-template-columns : repeat(4,1fr);
    }
    .elementor-grid-mobile-5 .elementor-grid {
    grid-template-columns : repeat(5,1fr);
    }
    .elementor-grid-mobile-6 .elementor-grid {
    grid-template-columns : repeat(6,1fr);
    }
    .elementor-grid-mobile-7 .elementor-grid {
    grid-template-columns : repeat(7,1fr);
    }
    .elementor-grid-mobile-8 .elementor-grid {
    grid-template-columns : repeat(8,1fr);
    }
    .elementor-grid-mobile-9 .elementor-grid {
    grid-template-columns : repeat(9,1fr);
    }
    .elementor-grid-mobile-10 .elementor-grid {
    grid-template-columns : repeat(10,1fr);
    }
    .elementor-grid-mobile-11 .elementor-grid {
    grid-template-columns : repeat(11,1fr);
    }
    .elementor-grid-mobile-12 .elementor-grid {
    grid-template-columns : repeat(12,1fr);
    }
    }
    @media (min-width: 1025px) {
    #elementor-device-mode:after {
    content : "desktop";
    }
    }
    @media {
    #elementor-device-mode:after {
    content : "widescreen";
    }
    }
    @media {
    #elementor-device-mode:after {
    content : "laptop";
    content : "tablet_extra";
    }
    }
    @media (max-width: 1024px) {
    #elementor-device-mode:after {
    content : "tablet";
    }
    }
    @media {
    #elementor-device-mode:after {
    content : "mobile_extra";
    }
    }
    @media (max-width: 767px) {
    #elementor-device-mode:after {
    content : "mobile";
    }
    }
    .e-con {
    --border-radius : 0;
    --display : flex;
    --flex-direction : column;
    --flex-basis : auto;
    --flex-grow : 0;
    --flex-shrink : 1;
    --container-widget-height : initial;
    --container-widget-flex-grow : 0;
    --container-widget-align-self : initial;
    --content-width : min(100%, var(--container-max-width, 1140px));
    --width : 100%;
    --min-height : initial;
    --height : auto;
    --text-align : initial;
    --margin-top : 0;
    --margin-right : 0;
    --margin-bottom : 0;
    --margin-left : 0;
    --padding-top : var(--container-default-padding-top, 10px);
    --padding-right : var(--container-default-padding-right, 10px);
    --padding-bottom : var(--container-default-padding-bottom, 10px);
    --padding-left : var(--container-default-padding-left, 10px);
    --position : relative;
    --z-index : revert;
    --overflow : visible;
    --gap : var(--widgets-spacing, 20px);
    --overlay-mix-blend-mode : initial;
    --overlay-opacity : 1;
    --overlay-transition : 0.3s;
    min-width : 0;
    transition : background var(--background-transition, 0.3s), border var(--border-transition, 0.3s), box-shadow var(--border-transition, 0.3s), transform var(--e-con-transform-transition-duration, 0.4s);
    }
    .e-con-full, .e-con > .e-con-inner {
    flex-direction : var(--flex-direction);
    text-align : var(--text-align);
    padding-top : var(--padding-top);
    padding-bottom : var(--padding-bottom);
    }
    .e-con, .e-con > .e-con-inner {
    display : var(--display);
    }
    .e-con-boxed {
    flex-direction : column;
    text-align : initial;
    flex-wrap : nowrap;
    justify-content : normal;
    align-items : normal;
    align-content : normal;
    gap : initial;
    }
    .e-con > .e-con-inner {
    flex-wrap : var(--flex-wrap);
    justify-content : var(--justify-content);
    align-items : var(--align-items);
    align-content : var(--align-content);
    gap : var(--gap);
    width : 100%;
    max-width : var(--content-width);
    margin : 0 auto;
    padding-inline : 0;
    height : 100%;
    flex-basis : auto;
    flex-grow : 1;
    flex-shrink : 1;
    align-self : auto;
    }
    :is(.elementor-section-wrap, [data-elementor-id]) > .e-con {
    --margin-right : auto;
    --margin-left : auto;
    max-width : min(100%, var(--width));
    }
    .e-con .elementor-widget.elementor-widget {
    margin-bottom : 0;
    }
    .e-con:before, .e-con > .e-con-inner > .elementor-background-slideshow:before, .e-con > .e-con-inner > .elementor-background-video-container:before, .e-con > .elementor-background-slideshow:before, .e-con > .elementor-background-video-container:before, .e-con > .elementor-motion-effects-container > .elementor-motion-effects-layer:before {
    display : block;
    position : absolute;
    width : 100%;
    height : 100%;
    left : 0;
    top : 0;
    mix-blend-mode : var(--overlay-mix-blend-mode);
    opacity : var(--overlay-opacity);
    transition : var(--overlay-transition, 0.3s);
    border-radius : var(--border-radius);
    }
    .e-con:before {
    transition : background var(--overlay-transition, 0.3s), border-radius var(--border-transition, 0.3s), opacity var(--overlay-transition, 0.3s);
    }
    .e-con > .e-con-inner > .elementor-background-video-container:before, .e-con > .elementor-background-video-container:before {
    z-index : 1;
    }
    .e-con > .e-con-inner > .elementor-background-slideshow:before, .e-con > .elementor-background-slideshow:before {
    z-index : 2;
    }
    .e-con .elementor-widget {
    min-width : 0;
    }
    .e-con .elementor-widget-empty, .e-con .elementor-widget-google_maps, .e-con .elementor-widget-video, .e-con .elementor-widget.e-widget-swiper {
    width : 100%;
    }
    .e-con > .e-con-inner > .elementor-widget > .elementor-widget-container, .e-con > .elementor-widget > .elementor-widget-container {
    height : 100%;
    }
    .e-con.e-con > .e-con-inner > .elementor-widget, .elementor.elementor .e-con > .elementor-widget {
    max-width : 100%;
    }
    @media (max-width: 767px) {
    .e-con {
    --width : 100%;
    --flex-wrap : wrap;
    }
    }
    .elementor-form-fields-wrapper {
    display : flex;
    flex-wrap : wrap;
    }
    .elementor-form-fields-wrapper.elementor-labels-above .elementor-field-group .elementor-field-subgroup, .elementor-form-fields-wrapper.elementor-labels-above .elementor-field-group > .elementor-select-wrapper, .elementor-form-fields-wrapper.elementor-labels-above .elementor-field-group > input, .elementor-form-fields-wrapper.elementor-labels-above .elementor-field-group > textarea {
    flex-basis : 100%;
    max-width : 100%;
    }
    .elementor-form-fields-wrapper.elementor-labels-inline > .elementor-field-group .elementor-select-wrapper, .elementor-form-fields-wrapper.elementor-labels-inline > .elementor-field-group > input {
    flex-grow : 1;
    }
    .elementor-field-group {
    flex-wrap : wrap;
    align-items : center;
    }
    .elementor-field-group.elementor-field-type-submit {
    align-items : flex-end;
    }
    .elementor-field-group .elementor-field-textual {
    width : 100%;
    max-width : 100%;
    border : #69727d solid 1px;
    background-color : transparent;
    color : #1a1c1e;
    vertical-align : middle;
    flex-grow : 1;
    }
    .elementor-field-group .elementor-field-textual:focus {
    box-shadow : 0 0 0 1px rgb(0, 0, 0, 0.1) inset;
    outline : 0;
    }
    .elementor-field-group .elementor-field-textual::-moz-placeholder {
    color : inherit;
    font-family : inherit;
    opacity : 0.6;
    }
    .elementor-field-group .elementor-field-textual::placeholder {
    color : inherit;
    font-family : inherit;
    opacity : 0.6;
    }
    .elementor-field-group .elementor-select-wrapper {
    display : flex;
    position : relative;
    width : 100%;
    }
    .elementor-field-group .elementor-select-wrapper select {
    appearance : none;
    color : inherit;
    font-size : inherit;
    font-family : inherit;
    font-weight : inherit;
    font-style : inherit;
    text-transform : inherit;
    letter-spacing : inherit;
    line-height : inherit;
    flex-basis : 100%;
    padding-right : 20px;
    }
    .elementor-field-group .elementor-select-wrapper:before {
    content : "\e92a";
    font-family : eicons;
    font-size : 15px;
    position : absolute;
    top : 50%;
    transform : translateY(-50%);
    right : 10px;
    pointer-events : none;
    text-shadow : 0 0 3px rgb(0, 0, 0, 0.3);
    }
    .elementor-field-group.elementor-field-type-select-multiple .elementor-select-wrapper:before {
    content : "";
    }
    .elementor-field-subgroup {
    display : flex;
    flex-wrap : wrap;
    }
    .elementor-field-subgroup .elementor-field-option label {
    display : inline-block;
    }
    .elementor-field-subgroup.elementor-subgroup-inline .elementor-field-option {
    padding-right : 10px;
    }
    .elementor-field-subgroup:not(.elementor-subgroup-inline) .elementor-field-option {
    flex-basis : 100%;
    }
    .elementor-field-type-acceptance .elementor-field-subgroup .elementor-field-option input, .elementor-field-type-acceptance .elementor-field-subgroup .elementor-field-option label, .elementor-field-type-checkbox .elementor-field-subgroup .elementor-field-option input, .elementor-field-type-checkbox .elementor-field-subgroup .elementor-field-option label, .elementor-field-type-radio .elementor-field-subgroup .elementor-field-option input, .elementor-field-type-radio .elementor-field-subgroup .elementor-field-option label {
    display : inline;
    }
    .elementor-field-label {
    cursor : pointer;
    }
    .elementor-mark-required .elementor-field-label:after {
    content : "*";
    color : red;
    padding-left : 0.2em;
    }
    .elementor-field-textual {
    line-height : 1.4;
    font-size : 15px;
    min-height : 40px;
    padding : 5px 14px;
    border-radius : 3px;
    }
    .elementor-field-textual.elementor-size-xs {
    font-size : 13px;
    min-height : 33px;
    padding : 4px 12px;
    border-radius : 2px;
    }
    .elementor-field-textual.elementor-size-md {
    font-size : 16px;
    min-height : 47px;
    padding : 6px 16px;
    border-radius : 4px;
    }
    .elementor-field-textual.elementor-size-lg {
    font-size : 18px;
    min-height : 59px;
    padding : 7px 20px;
    border-radius : 5px;
    }
    .elementor-field-textual.elementor-size-xl {
    font-size : 20px;
    min-height : 72px;
    padding : 8px 24px;
    border-radius : 6px;
    }
    .elementor-button-align-stretch .elementor-field-type-submit:not(.e-form__buttons__wrapper) .elementor-button {
    flex-basis : 100%;
    }
    .elementor-button-align-stretch .e-form__buttons__wrapper {
    flex-basis : 50%;
    flex-grow : 1;
    }
    .elementor-button-align-stretch .e-form__buttons__wrapper__button {
    flex-basis : 100%;
    }
    .elementor-button-align-center .e-form__buttons, .elementor-button-align-center .elementor-field-type-submit {
    justify-content : center;
    }
    .elementor-button-align-start .e-form__buttons, .elementor-button-align-start .elementor-field-type-submit {
    justify-content : flex-start;
    }
    .elementor-button-align-end .e-form__buttons, .elementor-button-align-end .elementor-field-type-submit {
    justify-content : flex-end;
    }
    .elementor-button-align-center .elementor-field-type-submit:not(.e-form__buttons__wrapper) .elementor-button, .elementor-button-align-end .elementor-field-type-submit:not(.e-form__buttons__wrapper) .elementor-button, .elementor-button-align-start .elementor-field-type-submit:not(.e-form__buttons__wrapper) .elementor-button {
    flex-basis : auto;
    }
    .elementor-button-align-center .e-form__buttons__wrapper, .elementor-button-align-end .e-form__buttons__wrapper, .elementor-button-align-start .e-form__buttons__wrapper {
    flex-grow : 0;
    }
    .elementor-button-align-center .e-form__buttons__wrapper, .elementor-button-align-center .e-form__buttons__wrapper__button, .elementor-button-align-end .e-form__buttons__wrapper, .elementor-button-align-end .e-form__buttons__wrapper__button, .elementor-button-align-start .e-form__buttons__wrapper, .elementor-button-align-start .e-form__buttons__wrapper__button {
    flex-basis : auto;
    }
    @media screen and (max-width: 1024px) {
    .elementor-tablet-button-align-stretch .elementor-field-type-submit:not(.e-form__buttons__wrapper) .elementor-button {
    flex-basis : 100%;
    }
    .elementor-tablet-button-align-stretch .e-form__buttons__wrapper {
    flex-basis : 50%;
    flex-grow : 1;
    }
    .elementor-tablet-button-align-stretch .e-form__buttons__wrapper__button {
    flex-basis : 100%;
    }
    .elementor-tablet-button-align-center .e-form__buttons, .elementor-tablet-button-align-center .elementor-field-type-submit {
    justify-content : center;
    }
    .elementor-tablet-button-align-start .e-form__buttons, .elementor-tablet-button-align-start .elementor-field-type-submit {
    justify-content : flex-start;
    }
    .elementor-tablet-button-align-end .e-form__buttons, .elementor-tablet-button-align-end .elementor-field-type-submit {
    justify-content : flex-end;
    }
    .elementor-tablet-button-align-center .elementor-field-type-submit:not(.e-form__buttons__wrapper) .elementor-button, .elementor-tablet-button-align-end .elementor-field-type-submit:not(.e-form__buttons__wrapper) .elementor-button, .elementor-tablet-button-align-start .elementor-field-type-submit:not(.e-form__buttons__wrapper) .elementor-button {
    flex-basis : auto;
    }
    .elementor-tablet-button-align-center .e-form__buttons__wrapper, .elementor-tablet-button-align-end .e-form__buttons__wrapper, .elementor-tablet-button-align-start .e-form__buttons__wrapper {
    flex-grow : 0;
    }
    .elementor-tablet-button-align-center .e-form__buttons__wrapper, .elementor-tablet-button-align-center .e-form__buttons__wrapper__button, .elementor-tablet-button-align-end .e-form__buttons__wrapper, .elementor-tablet-button-align-end .e-form__buttons__wrapper__button, .elementor-tablet-button-align-start .e-form__buttons__wrapper, .elementor-tablet-button-align-start .e-form__buttons__wrapper__button {
    flex-basis : auto;
    }
    }
    @media screen and (max-width: 767px) {
    .elementor-mobile-button-align-stretch .elementor-field-type-submit:not(.e-form__buttons__wrapper) .elementor-button {
    flex-basis : 100%;
    }
    .elementor-mobile-button-align-stretch .e-form__buttons__wrapper {
    flex-basis : 50%;
    flex-grow : 1;
    }
    .elementor-mobile-button-align-stretch .e-form__buttons__wrapper__button {
    flex-basis : 100%;
    }
    .elementor-mobile-button-align-center .e-form__buttons, .elementor-mobile-button-align-center .elementor-field-type-submit {
    justify-content : center;
    }
    .elementor-mobile-button-align-start .e-form__buttons, .elementor-mobile-button-align-start .elementor-field-type-submit {
    justify-content : flex-start;
    }
    .elementor-mobile-button-align-end .e-form__buttons, .elementor-mobile-button-align-end .elementor-field-type-submit {
    justify-content : flex-end;
    }
    .elementor-mobile-button-align-center .elementor-field-type-submit:not(.e-form__buttons__wrapper) .elementor-button, .elementor-mobile-button-align-end .elementor-field-type-submit:not(.e-form__buttons__wrapper) .elementor-button, .elementor-mobile-button-align-start .elementor-field-type-submit:not(.e-form__buttons__wrapper) .elementor-button {
    flex-basis : auto;
    }
    .elementor-mobile-button-align-center .e-form__buttons__wrapper, .elementor-mobile-button-align-end .e-form__buttons__wrapper, .elementor-mobile-button-align-start .e-form__buttons__wrapper {
    flex-grow : 0;
    }
    .elementor-mobile-button-align-center .e-form__buttons__wrapper, .elementor-mobile-button-align-center .e-form__buttons__wrapper__button, .elementor-mobile-button-align-end .e-form__buttons__wrapper, .elementor-mobile-button-align-end .e-form__buttons__wrapper__button, .elementor-mobile-button-align-start .e-form__buttons__wrapper, .elementor-mobile-button-align-start .e-form__buttons__wrapper__button {
    flex-basis : auto;
    }
    }
    .elementor-error .elementor-field {
    border-color : #d9534f;
    }
    .elementor-error .help-inline {
    color : #d9534f;
    font-size : 0.9em;
    }
    .elementor-message {
    margin : 10px 0;
    font-size : 1em;
    line-height : 1;
    }
    .elementor-message:before {
    content : "\e90e";
    display : inline-block;
    font-family : eicons;
    font-weight : 400;
    font-style : normal;
    vertical-align : middle;
    margin-right : 5px;
    }
    .elementor-message.elementor-message-danger {
    color : #d9534f;
    }
    .elementor-message.elementor-message-danger:before {
    content : "\e87f";
    }
    .elementor-message.form-message-success {
    color : #5cb85c;
    }
    .elementor-form .elementor-button {
    padding-top : 0;
    padding-bottom : 0;
    border : none;
    }
    .elementor-form .elementor-button > span {
    display : flex;
    justify-content : center;
    }
    .elementor-form .elementor-button.elementor-size-xs {
    min-height : 33px;
    }
    .elementor-form .elementor-button.elementor-size-sm {
    min-height : 40px;
    }
    .elementor-form .elementor-button.elementor-size-md {
    min-height : 47px;
    }
    .elementor-form .elementor-button.elementor-size-lg {
    min-height : 59px;
    }
    .elementor-form .elementor-button.elementor-size-xl {
    min-height : 72px;
    }
    .elementor-element .elementor-widget-container {
    transition : background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s, transform var(--e-transform-transition-duration, 0.4s);
    }
    .elementor-button {
    display : inline-block;
    line-height : 1;
    background-color : #69727d;
    font-size : 15px;
    padding : 12px 24px;
    border-radius : 3px;
    color : #fff;
    fill : #fff;
    text-align : center;
    transition : all 0.3s;
    }
    .elementor-button:focus, .elementor-button:hover, .elementor-button:visited {
    color : #fff;
    }
    .elementor-button-content-wrapper {
    display : flex;
    justify-content : center;
    }
    .elementor-button-icon {
    flex-grow : 0;
    order : 5;
    }
    .elementor-button-icon svg {
    width : 1em;
    height : auto;
    }
    .elementor-button-icon .e-font-icon-svg {
    height : 1em;
    }
    .elementor-button-text {
    flex-grow : 1;
    order : 10;
    display : inline-block;
    }
    .elementor-button.elementor-size-xs {
    font-size : 13px;
    padding : 10px 20px;
    border-radius : 2px;
    }
    .elementor-button.elementor-size-md {
    font-size : 16px;
    padding : 15px 30px;
    border-radius : 4px;
    }
    .elementor-button.elementor-size-lg {
    font-size : 18px;
    padding : 20px 40px;
    border-radius : 5px;
    }
    .elementor-button.elementor-size-xl {
    font-size : 20px;
    padding : 25px 50px;
    border-radius : 6px;
    }
    .elementor-button .elementor-align-icon-right {
    margin-left : 5px;
    order : 15;
    }
    .elementor-button .elementor-align-icon-left {
    margin-right : 5px;
    order : 5;
    }
    .elementor-button span {
    text-decoration : inherit;
    }
    .elementor-element.elementor-button-info .elementor-button {
    background-color : #5bc0de;
    }
    .elementor-element.elementor-button-success .elementor-button {
    background-color : #5cb85c;
    }
    .elementor-element.elementor-button-warning .elementor-button {
    background-color : #f0ad4e;
    }
    .elementor-element.elementor-button-danger .elementor-button {
    background-color : #d9534f;
    }
    .elementor-widget-button .elementor-button .elementor-button-info {
    background-color : #5bc0de;
    }
    .elementor-widget-button .elementor-button .elementor-button-success {
    background-color : #5cb85c;
    }
    .elementor-widget-button .elementor-button .elementor-button-warning {
    background-color : #f0ad4e;
    }
    .elementor-widget-button .elementor-button .elementor-button-danger {
    background-color : #d9534f;
    }
    .elementor-tab-title a {
    color : inherit;
    }
    .elementor-view-stacked .elementor-icon {
    padding : 0.5em;
    background-color : #69727d;
    color : #fff;
    fill : #fff;
    }
    .elementor-view-framed .elementor-icon {
    padding : 0.5em;
    color : #69727d;
    border : #69727d solid 3px;
    background-color : transparent;
    }
    .elementor-icon {
    display : inline-block;
    line-height : 1;
    transition : all 0.3s;
    color : #69727d;
    font-size : 50px;
    text-align : center;
    }
    .elementor-icon:hover {
    color : #69727d;
    }
    .elementor-icon i, .elementor-icon svg {
    width : 1em;
    height : 1em;
    position : relative;
    display : block;
    }
    .elementor-icon i:before, .elementor-icon svg:before {
    position : absolute;
    left : 50%;
    transform : translateX(-50%);
    }
    .elementor-icon i.fad {
    width : auto;
    }
    .elementor-shape-circle .elementor-icon {
    border-radius : 50%;
    }
    .e-transform .elementor-widget-container {
    transform : perspective(var(--e-transform-perspective, 0)) rotate(var(--e-transform-rotateZ, 0)) rotateX(var(--e-transform-rotateX, 0)) rotateY(var(--e-transform-rotateY, 0)) translate(var(--e-transform-translate, 0)) translateX(var(--e-transform-translateX, 0)) translateY(var(--e-transform-translateY, 0)) scaleX(calc(var(--e-transform-flipX, 1) * var(--e-transform-scaleX, var(--e-transform-scale, 1)))) scaleY(calc(var(--e-transform-flipY, 1) * var(--e-transform-scaleY, var(--e-transform-scale, 1)))) skewX(var(--e-transform-skewX, 0)) skewY(var(--e-transform-skewY, 0));
    }
    .e-con.e-transform {
    transform : perspective(var(--e-con-transform-perspective, 0)) rotate(var(--e-con-transform-rotateZ, 0)) rotateX(var(--e-con-transform-rotateX, 0)) rotateY(var(--e-con-transform-rotateY, 0)) translate(var(--e-con-transform-translate, 0)) translateX(var(--e-con-transform-translateX, 0)) translateY(var(--e-con-transform-translateY, 0)) scaleX(calc(var(--e-con-transform-flipX, 1) * var(--e-con-transform-scaleX, var(--e-con-transform-scale, 1)))) scaleY(calc(var(--e-con-transform-flipY, 1) * var(--e-con-transform-scaleY, var(--e-con-transform-scale, 1)))) skewX(var(--e-con-transform-skewX, 0)) skewY(var(--e-con-transform-skewY, 0));
    }
    .elementor-element, .elementor-lightbox {
    --swiper-theme-color : #000;
    --swiper-navigation-size : 44px;
    --swiper-pagination-bullet-size : 6px;
    --swiper-pagination-bullet-horizontal-gap : 6px;
    }
    .elementor-element .swiper .swiper-slide figure, .elementor-lightbox .swiper .swiper-slide figure {
    line-height : 0;
    }
    .elementor-element .swiper .elementor-lightbox-content-source, .elementor-lightbox .swiper .elementor-lightbox-content-source {
    display : none;
    }
    .elementor-element .swiper .elementor-swiper-button, .elementor-lightbox .swiper .elementor-swiper-button {
    position : absolute;
    display : inline-flex;
    z-index : 1;
    cursor : pointer;
    font-size : 25px;
    color : hsl(0 0% 93.3% / 0.9);
    top : 50%;
    transform : translateY(-50%);
    }
    .elementor-element .swiper .elementor-swiper-button svg, .elementor-lightbox .swiper .elementor-swiper-button svg {
    fill : hsl(0 0% 93.3% / 0.9);
    height : 1em;
    width : 1em;
    }
    .elementor-element .swiper .elementor-swiper-button-prev, .elementor-lightbox .swiper .elementor-swiper-button-prev {
    left : 10px;
    }
    .elementor-element .swiper .elementor-swiper-button-next, .elementor-lightbox .swiper .elementor-swiper-button-next {
    right : 10px;
    }
    .elementor-element .swiper .elementor-swiper-button.swiper-button-disabled, .elementor-lightbox .swiper .elementor-swiper-button.swiper-button-disabled {
    opacity : 0.3;
    }
    .elementor-element .swiper .swiper-image-stretch .swiper-slide .swiper-slide-image, .elementor-lightbox .swiper .swiper-image-stretch .swiper-slide .swiper-slide-image {
    width : 100%;
    }
    .elementor-element .swiper .swiper-horizontal > .swiper-pagination-bullets, .elementor-element .swiper .swiper-pagination-bullets.swiper-pagination-horizontal, .elementor-element .swiper .swiper-pagination-custom, .elementor-element .swiper .swiper-pagination-fraction, .elementor-lightbox .swiper .swiper-horizontal > .swiper-pagination-bullets, .elementor-lightbox .swiper .swiper-pagination-bullets.swiper-pagination-horizontal, .elementor-lightbox .swiper .swiper-pagination-custom, .elementor-lightbox .swiper .swiper-pagination-fraction {
    bottom : 5px;
    }
    .elementor-element .swiper.swiper-cube .elementor-swiper-button, .elementor-lightbox .swiper.swiper-cube .elementor-swiper-button {
    transform : translate3d(0,-50%,1px);
    }
    .elementor-element.elementor-pagination-position-outside .swiper, .elementor-lightbox.elementor-pagination-position-outside .swiper {
    padding-bottom : 30px;
    }
    .elementor-element.elementor-pagination-position-outside .swiper .elementor-swiper-button, .elementor-lightbox.elementor-pagination-position-outside .swiper .elementor-swiper-button {
    top : calc(50% - 30px / 2);
    }
    .elementor-element .elementor-swiper, .elementor-lightbox .elementor-swiper {
    position : relative;
    }
    .elementor-element .elementor-main-swiper, .elementor-lightbox .elementor-main-swiper {
    position : static;
    }
    .elementor-element.elementor-arrows-position-outside .swiper, .elementor-lightbox.elementor-arrows-position-outside .swiper {
    width : calc(100% - 60px);
    }
    .elementor-element.elementor-arrows-position-outside .swiper .elementor-swiper-button-prev, .elementor-lightbox.elementor-arrows-position-outside .swiper .elementor-swiper-button-prev {
    left : 0;
    }
    .elementor-element.elementor-arrows-position-outside .swiper .elementor-swiper-button-next, .elementor-lightbox.elementor-arrows-position-outside .swiper .elementor-swiper-button-next {
    right : 0;
    }
    .elementor-lightbox {
    --lightbox-ui-color : hsl(0 0% 93.3% / 0.9);
    --lightbox-ui-color-hover : #fff;
    --lightbox-text-color : var(--lightbox-ui-color);
    --lightbox-header-icons-size : 20px;
    --lightbox-navigation-icons-size : 25px;
    }
    .elementor-lightbox .dialog-header {
    display : none;
    }
    .elementor-lightbox .dialog-widget-content {
    background : none;
    box-shadow : none;
    width : 100%;
    height : 100%;
    }
    .elementor-lightbox .dialog-message {
    animation-duration : 0.3s;
    }
    .elementor-lightbox .dialog-message:not(.elementor-fit-aspect-ratio) {
    height : 100%;
    }
    .elementor-lightbox .dialog-message.dialog-lightbox-message {
    padding : 0;
    }
    .elementor-lightbox .dialog-lightbox-close-button {
    cursor : pointer;
    position : absolute;
    font-size : var(--lightbox-header-icons-size);
    right : 0.75em;
    margin-top : 13px;
    padding : 0.25em;
    z-index : 2;
    line-height : 1;
    display : flex;
    }
    .elementor-lightbox .dialog-lightbox-close-button svg {
    height : 1em;
    width : 1em;
    }
    .elementor-lightbox .dialog-lightbox-close-button, .elementor-lightbox .elementor-swiper-button {
    color : var(--lightbox-ui-color);
    transition : all 0.3s;
    opacity : 1;
    }
    .elementor-lightbox .dialog-lightbox-close-button svg, .elementor-lightbox .elementor-swiper-button svg {
    fill : var(--lightbox-ui-color);
    }
    .elementor-lightbox .dialog-lightbox-close-button:hover, .elementor-lightbox .elementor-swiper-button:hover {
    color : var(--lightbox-ui-color-hover);
    }
    .elementor-lightbox .dialog-lightbox-close-button:hover svg, .elementor-lightbox .elementor-swiper-button:hover svg {
    fill : var(--lightbox-ui-color-hover);
    }
    .elementor-lightbox .swiper, .elementor-lightbox .swiper-container {
    height : 100%;
    }
    .elementor-lightbox .elementor-lightbox-item {
    display : flex;
    align-items : center;
    justify-content : center;
    position : relative;
    padding : 70px;
    box-sizing : border-box;
    height : 100%;
    margin : auto;
    }
    @media (max-width: 767px) {
    .elementor-lightbox .elementor-lightbox-item {
    padding : 70px 0;
    }
    }
    .elementor-lightbox .elementor-lightbox-image {
    max-height : 100%;
    user-select : none;
    }
    .elementor-lightbox .elementor-lightbox-image, .elementor-lightbox .elementor-lightbox-image:hover {
    opacity : 1;
    border : none;
    }
    .elementor-lightbox .elementor-lightbox-image, .elementor-lightbox .elementor-video-container {
    box-shadow : 0 0 30px rgb(0, 0, 0, 0.3), 0 0 8px -5px rgb(0, 0, 0, 0.3);
    border-radius : 2px;
    }
    .elementor-lightbox .elementor-video-container {
    position : absolute;
    top : 50%;
    left : 50%;
    transform : translate(-50%,-50%);
    }
    @media (min-width: 1025px) {
    .elementor-lightbox .elementor-video-container {
    width : 75%;
    }
    }
    @media (max-width: 1024px) {
    .elementor-lightbox .elementor-video-container {
    width : 100%;
    }
    }
    @media (min-width: 768px) and (max-width: 1024px) {
    .elementor-lightbox .elementor-aspect-ratio-916 .elementor-video-container {
    width : 70%;
    }
    }
    .elementor-lightbox .swiper-container .elementor-swiper-button-prev, .elementor-lightbox .swiper .elementor-swiper-button-prev {
    left : 0;
    }
    .elementor-lightbox .swiper-container .elementor-swiper-button-next, .elementor-lightbox .swiper .elementor-swiper-button-next {
    right : 0;
    }
    .elementor-lightbox .swiper-container .swiper-pagination-fraction, .elementor-lightbox .swiper .swiper-pagination-fraction {
    width : max-content;
    color : #fff;
    }
    .elementor-lightbox .elementor-swiper-button:focus {
    outline-width : 1px;
    }
    .elementor-lightbox .elementor-swiper-button-next, .elementor-lightbox .elementor-swiper-button-prev {
    height : 100%;
    display : flex;
    align-items : center;
    width : 15%;
    justify-content : center;
    font-size : var(--lightbox-navigation-icons-size);
    }
    @media (max-width: 767px) {
    .elementor-lightbox .elementor-swiper-button:focus {
    outline : none;
    }
    .elementor-lightbox .elementor-swiper-button-next, .elementor-lightbox .elementor-swiper-button-prev {
    width : 20%;
    }
    .elementor-lightbox .elementor-swiper-button-next i, .elementor-lightbox .elementor-swiper-button-prev i {
    padding : 10px;
    background-color : rgb(0, 0, 0, 0.5);
    }
    .elementor-lightbox .elementor-swiper-button-prev {
    left : 0;
    justify-content : flex-start;
    }
    .elementor-lightbox .elementor-swiper-button-next {
    right : 0;
    justify-content : flex-end;
    }
    }
    .elementor-slideshow__counter {
    color : currentColor;
    font-size : 0.75em;
    width : max-content;
    }
    .elementor-slideshow__footer, .elementor-slideshow__header {
    position : absolute;
    left : 0;
    width : 100%;
    padding : 15px 20px;
    transition : 0.3s;
    }
    .elementor-slideshow__footer {
    color : var(--lightbox-text-color);
    }
    .elementor-slideshow__header {
    color : var(--lightbox-ui-color);
    display : flex;
    flex-direction : row-reverse;
    font-size : var(--lightbox-header-icons-size);
    padding-left : 1em;
    padding-right : 2.6em;
    top : 0;
    align-items : center;
    z-index : 10;
    }
    .elementor-slideshow__header > i, .elementor-slideshow__header > svg {
    cursor : pointer;
    padding : 0.25em;
    margin : 0 0.35em;
    }
    .elementor-slideshow__header > i {
    font-size : inherit;
    }
    .elementor-slideshow__header > i:hover {
    color : var(--lightbox-ui-color-hover);
    }
    .elementor-slideshow__header > svg {
    box-sizing : content-box;
    fill : var(--lightbox-ui-color);
    height : 1em;
    width : 1em;
    }
    .elementor-slideshow__header > svg:hover {
    fill : var(--lightbox-ui-color-hover);
    }
    .elementor-slideshow__header .elementor-slideshow__counter {
    margin-right : auto;
    }
    .elementor-slideshow__header .elementor-icon-share {
    z-index : 5;
    }
    .elementor-slideshow__share-menu {
    background-color : transparent;
    width : 0;
    height : 0;
    position : absolute;
    overflow : hidden;
    transition : background-color 0.4s;
    }
    .elementor-slideshow__share-menu .elementor-slideshow__share-links a {
    color : #0c0d0e;
    }
    .elementor-slideshow__share-links {
    display : block;
    position : absolute;
    min-width : 200px;
    right : 2.8em;
    top : 3em;
    background-color : #fff;
    border-radius : 3px;
    padding : 14px 20px;
    transform : scale(0);
    opacity : 0;
    transform-origin : 90% 10%;
    transition : all 0.25s 0.1s;
    box-shadow : 0 4px 15px rgb(0, 0, 0, 0.3);
    }
    .elementor-slideshow__share-links a {
    text-align : left;
    color : #3a3f45;
    font-size : 12px;
    line-height : 2.5;
    display : block;
    opacity : 0;
    transition : opacity 0.5s 0.1s;
    }
    .elementor-slideshow__share-links a:hover {
    color : #000;
    }
    .elementor-slideshow__share-links a i, .elementor-slideshow__share-links a svg {
    margin-right : 0.75em;
    }
    .elementor-slideshow__share-links a i {
    font-size : 1.25em;
    }
    .elementor-slideshow__share-links a svg {
    height : 1.25em;
    width : 1.25em;
    }
    .elementor-slideshow__share-links:before {
    content : "";
    display : block;
    position : absolute;
    top : 1px;
    right : 0.5em;
    border : transparent solid 0.45em;
    border-bottom-color : #fff;
    transform : translateY(-100%) scaleX(0.7);
    }
    .elementor-slideshow__footer {
    bottom : 0;
    z-index : 5;
    position : fixed;
    }
    .elementor-slideshow__description, .elementor-slideshow__title {
    margin : 0;
    }
    .elementor-slideshow__title {
    font-size : 16px;
    font-weight : 700;
    }
    .elementor-slideshow__description {
    font-size : 14px;
    }
    .elementor-slideshow--ui-hidden .elementor-slideshow__footer, .elementor-slideshow--ui-hidden .elementor-slideshow__header {
    opacity : 0;
    pointer-events : none;
    }
    .elementor-slideshow--ui-hidden .elementor-swiper-button-next, .elementor-slideshow--ui-hidden .elementor-swiper-button-prev {
    opacity : 0;
    }
    .elementor-slideshow--fullscreen-mode .elementor-video-container {
    width : 100%;
    }
    .elementor-slideshow--zoom-mode .elementor-slideshow__footer, .elementor-slideshow--zoom-mode .elementor-slideshow__header {
    background-color : rgb(0, 0, 0, 0.5);
    }
    .elementor-slideshow--zoom-mode .elementor-swiper-button-next, .elementor-slideshow--zoom-mode .elementor-swiper-button-prev {
    opacity : 0;
    pointer-events : none;
    }
    .elementor-slideshow--share-mode .elementor-slideshow__share-menu {
    top : 0;
    left : 0;
    width : 100vw;
    height : 100vh;
    opacity : 1;
    cursor : default;
    background-color : rgb(0, 0, 0, 0.5);
    }
    .elementor-slideshow--share-mode .elementor-slideshow__share-links {
    transform : scale(1);
    }
    .elementor-slideshow--share-mode .elementor-slideshow__share-links, .elementor-slideshow--share-mode .elementor-slideshow__share-links a {
    opacity : 1;
    }
    .elementor-slideshow--share-mode .elementor-slideshow__share-links .eicon-twitter {
    color : #1da1f2;
    }
    .elementor-slideshow--share-mode .elementor-slideshow__share-links .eicon-facebook {
    color : #3b5998;
    }
    .elementor-slideshow--share-mode .elementor-slideshow__share-links .eicon-pinterest {
    color : #bd081c;
    }
    .elementor-slideshow--share-mode .elementor-slideshow__share-links .eicon-download-bold {
    color : #9da5ae;
    }
    .elementor-slideshow--share-mode .elementor-slideshow__share-links .e-eicon-twitter {
    fill : #1da1f2;
    }
    .elementor-slideshow--share-mode .elementor-slideshow__share-links .e-eicon-facebook {
    fill : #3b5998;
    }
    .elementor-slideshow--share-mode .elementor-slideshow__share-links .e-eicon-pinterest {
    fill : #bd081c;
    }
    .elementor-slideshow--share-mode .elementor-slideshow__share-links .e-eicon-download-bold {
    fill : #9da5ae;
    }
    .elementor-slideshow--share-mode .eicon-share-arrow {
    z-index : 2;
    }
    .animated {
    animation-duration : 1.25s;
    }
    .animated.animated-slow {
    animation-duration : 2s;
    }
    .animated.animated-fast {
    animation-duration : 0.75s;
    }
    .animated.infinite {
    animation-iteration-count : infinite;
    }
    .animated.reverse {
    animation-direction : reverse;
    animation-fill-mode : forwards;
    }
    @media (prefers-reduced-motion: reduce) {
    .animated {
    animation : none;
    }
    }
    .elementor-shape {
    overflow : hidden;
    position : absolute;
    left : 0;
    width : 100%;
    line-height : 0;
    direction : ltr;
    }
    .elementor-shape-top {
    top : -1px;
    }
    .elementor-shape-top:not([data-negative="false"]) svg {
    z-index : -1;
    }
    .elementor-shape-bottom {
    bottom : -1px;
    }
    .elementor-shape-bottom:not([data-negative="true"]) svg {
    z-index : -1;
    }
    .elementor-shape[data-negative="false"].elementor-shape-bottom, .elementor-shape[data-negative="true"].elementor-shape-top {
    transform : rotate(180deg);
    }
    .elementor-shape svg {
    display : block;
    width : calc(100% + 1.3px);
    position : relative;
    left : 50%;
    transform : translateX(-50%);
    }
    .elementor-shape .elementor-shape-fill {
    fill : #fff;
    transform-origin : center;
    transform : rotateY(0deg);
    }
    #wp-admin-bar-elementor_edit_page > .ab-item:before {
    content : "\e813";
    font-family : eicons;
    top : 3px;
    font-size : 18px;
    }
    #wp-admin-bar-elementor_edit_page .ab-submenu .ab-item {
    display : flex;
    width : 200px;
    }
    #wp-admin-bar-elementor_edit_page .elementor-edit-link-title {
    white-space : nowrap;
    text-overflow : ellipsis;
    overflow : hidden;
    width : 100%;
    }
    #wp-admin-bar-elementor_edit_page .elementor-edit-link-type {
    background : #3a3f45;
    font-size : 11px;
    line-height : 9px;
    margin-top : 6px;
    padding : 4px 8px;
    border-radius : 3px;
    }
    #wp-admin-bar-elementor_inspector > .ab-item:before {
    content : "\f348";
    top : 2px;
    }
    #wpadminbar * {
    font-style : normal;
    }
    .page-template-elementor_canvas.elementor-page:before {
    display : none;
    }
    .elementor-post__thumbnail__link {
    transition : none;
    }
    #left-area ul.elementor-icon-list-items, .elementor-edit-area .elementor-element ul.elementor-icon-list-items, .elementor .elementor-element ul.elementor-icon-list-items {
    padding : 0;
    }
    .e--ua-appleWebkit.rtl {
    --flex-right : flex-start;
    }
    .e--ua-appleWebkit .elementor-share-buttons--align-right, .e--ua-appleWebkit .elementor-widget-social-icons.e-grid-align-right {
    --justify-content : var(--flex-right, flex-end);
    }
    .e--ua-appleWebkit .elementor-share-buttons--align-center, .e--ua-appleWebkit .elementor-widget-social-icons.e-grid-align-center {
    --justify-content : center;
    }
    .e--ua-appleWebkit .elementor-grid-0.elementor-share-buttons--align-center .elementor-grid, .e--ua-appleWebkit .elementor-grid-0.elementor-share-buttons--align-justify .elementor-grid, .e--ua-appleWebkit .elementor-grid-0.elementor-share-buttons--align-right .elementor-grid, .e--ua-appleWebkit .elementor-grid-0.elementor-widget-social-icons.e-grid-align-center .elementor-grid, .e--ua-appleWebkit .elementor-grid-0.elementor-widget-social-icons.e-grid-align-right .elementor-grid {
    width : auto;
    display : flex;
    flex-wrap : wrap;
    justify-content : var(--justify-content, space-between);
    }
    .e--ua-appleWebkit .elementor-grid-0.elementor-share-buttons--align-left .elementor-grid, .e--ua-appleWebkit .elementor-grid-0.elementor-widget-social-icons.e-grid-align-left .elementor-grid {
    display : inline-block;
    }
    .e--ua-appleWebkit .elementor-grid-0.elementor-share-buttons--align-left .elementor-grid, .e--ua-appleWebkit .elementor-grid-0.elementor-share-buttons--align-left .elementor-grid-item, .e--ua-appleWebkit .elementor-grid-0.elementor-widget-social-icons.e-grid-align-left .elementor-grid, .e--ua-appleWebkit .elementor-grid-0.elementor-widget-social-icons.e-grid-align-left .elementor-grid-item {
    margin-left : 0;
    margin-right : 0;
    }
    @media (max-width: 1024px) {
    .e--ua-appleWebkit .elementor-share-buttons-tablet--align-right, .e--ua-appleWebkit .elementor-widget-social-icons.e-grid-align-tablet-right {
    --justify-content : var(--flex-right, flex-end);
    }
    .e--ua-appleWebkit .elementor-share-buttons-tablet--align-center, .e--ua-appleWebkit .elementor-widget-social-icons.e-grid-align-tablet-center {
    --justify-content : center;
    }
    .e--ua-appleWebkit .elementor-grid-0.elementor-share-buttons--align-tablet-center .elementor-grid, .e--ua-appleWebkit .elementor-grid-0.elementor-share-buttons--align-tablet-justify .elementor-grid, .e--ua-appleWebkit .elementor-grid-0.elementor-share-buttons--align-tablet-right .elementor-grid, .e--ua-appleWebkit .elementor-grid-0.elementor-widget-social-icons.e-grid-align-tablet-center .elementor-grid, .e--ua-appleWebkit .elementor-grid-0.elementor-widget-social-icons.e-grid-align-tablet-right .elementor-grid {
    width : auto;
    display : flex;
    flex-wrap : wrap;
    justify-content : var(--justify-content, space-between);
    }
    .e--ua-appleWebkit .elementor-grid-0.elementor-share-buttons-tablet--align-left .elementor-grid, .e--ua-appleWebkit .elementor-grid-0.elementor-widget-social-icons.e-grid-align-tablet-left .elementor-grid {
    display : inline-block;
    }
    .e--ua-appleWebkit .elementor-grid-0.elementor-share-buttons-tablet--align-left .elementor-grid, .e--ua-appleWebkit .elementor-grid-0.elementor-share-buttons-tablet--align-left .elementor-grid-item, .e--ua-appleWebkit .elementor-grid-0.elementor-widget-social-icons.e-grid-align-tablet-left .elementor-grid, .e--ua-appleWebkit .elementor-grid-0.elementor-widget-social-icons.e-grid-align-tablet-left .elementor-grid-item {
    margin-left : 0;
    margin-right : 0;
    }
    }
    @media (max-width: 767px) {
    .e--ua-appleWebkit .elementor-share-buttons-mobile--align-right, .e--ua-appleWebkit .elementor-widget-social-icons.e-grid-align-mobile-right {
    --justify-content : var(--flex-right, flex-end);
    }
    .e--ua-appleWebkit .elementor-share-buttons-mobile--align-center, .e--ua-appleWebkit .elementor-widget-social-icons.e-grid-align-mobile-center {
    --justify-content : center;
    }
    .e--ua-appleWebkit .elementor-grid-0.elementor-share-buttons--align-mobile-center .elementor-grid, .e--ua-appleWebkit .elementor-grid-0.elementor-share-buttons--align-mobile-justify .elementor-grid, .e--ua-appleWebkit .elementor-grid-0.elementor-share-buttons--align-mobile-right .elementor-grid, .e--ua-appleWebkit .elementor-grid-0.elementor-widget-social-icons.e-grid-align-mobile-center .elementor-grid, .e--ua-appleWebkit .elementor-grid-0.elementor-widget-social-icons.e-grid-align-mobile-right .elementor-grid {
    width : auto;
    display : flex;
    flex-wrap : wrap;
    justify-content : var(--justify-content, space-between);
    }
    .e--ua-appleWebkit .elementor-grid-0.elementor-share-buttons-mobile--align-left .elementor-grid, .e--ua-appleWebkit .elementor-grid-0.elementor-widget-social-icons.e-grid-align-mobile-left .elementor-grid {
    display : inline-block;
    }
    .e--ua-appleWebkit .elementor-grid-0.elementor-share-buttons-mobile--align-left .elementor-grid, .e--ua-appleWebkit .elementor-grid-0.elementor-share-buttons-mobile--align-left .elementor-grid-item, .e--ua-appleWebkit .elementor-grid-0.elementor-widget-social-icons.e-grid-align-mobile-left .elementor-grid, .e--ua-appleWebkit .elementor-grid-0.elementor-widget-social-icons.e-grid-align-mobile-left .elementor-grid-item {
    margin-left : 0;
    margin-right : 0;
    }
    }
    @media (max-width: 767px) {
    .elementor .elementor-hidden-mobile, .elementor .elementor-hidden-phone {
    display : none;
    }
    }
    @media {
    .elementor .elementor-hidden-mobile_extra {
    display : none;
    }
    }
    @media (min-width: 768px) and (max-width: 1024px) {
    .elementor .elementor-hidden-tablet {
    display : none;
    }
    }
    @media {
    .elementor .elementor-hidden-laptop, .elementor .elementor-hidden-tablet_extra {
    display : none;
    }
    }
    @media (min-width: 1025px) and (max-width: 99999px) {
    .elementor .elementor-hidden-desktop {
    display : none;
    }
    }
    @media {
    .elementor .elementor-hidden-widescreen {
    display : none;
    }
    }
    .elementor-widget-text-path {
    font-size : 20px;
    text-align : var(--alignment, left);
    }
    .elementor-widget-text-path svg {
    width : var(--width);
    max-width : 100%;
    height : auto;
    overflow : visible;
    transform : rotate(var(--rotate, 0)) scaleX(var(--scale-x, 1)) scaleY(var(--scale-y, 1));
    }
    .elementor-widget-text-path svg path {
    fill : transparent;
    stroke : transparent;
    stroke-width : var(--stroke-width, 1px);
    }
    .elementor-widget-text-path svg:hover path {
    --path-fill : var(--path-fill-hover);
    --stroke-color : var(--stroke-color-hover);
    --stroke-width : var(--stroke-width-hover);
    }
    .elementor-widget-text-path svg text {
    --fill : var(--text-color);
    direction : var(--direction, ltr);
    }
    .elementor-widget-text-path svg text:hover {
    --color : var(--text-color-hover, var(--text-color));
    --fill : var(--color);
    }
    .elementor-widget-n-tabs {
    --n-tabs-color-accent-fallback : #61ce70;
    --n-tabs-color-secondary-fallback : #54595f;
    --n-tabs-default-padding-block : 15px;
    --n-tabs-default-padding-inline : 35px;
    --n-tabs-background-color : transparent;
    --n-tabs-display : flex;
    --n-tabs-direction : column;
    --n-tabs-gap : 10px;
    --n-tabs-heading-display : flex;
    --n-tabs-heading-direction : row;
    --n-tabs-heading-grow : initial;
    --n-tabs-heading-justify-content : center;
    --n-tabs-heading-width : initial;
    --n-tabs-border-width : 1px;
    --n-tabs-border-color : #d5d8dc;
    --n-tabs-content-padding : initial;
    --n-tabs-content-border-radius : initial;
    --n-tabs-title-color : var(--e-global-color-secondary, var(--n-tabs-color-secondary-fallback));
    --n-tabs-title-color-hover : #fff;
    --n-tabs-title-color-active : #fff;
    --n-tabs-title-background-color : #f1f2f3;
    --n-tabs-title-background-color-hover : var(--e-global-color-accent, var(--n-tabs-color-accent-fallback));
    --n-tabs-title-background-color-active : var(--e-global-color-accent, var(--n-tabs-color-accent-fallback));
    --n-tabs-title-width : initial;
    --n-tabs-title-height : initial;
    --n-tabs-title-font-size : 1rem;
    --n-tabs-title-justify-content-toggle : initial;
    --n-tabs-title-align-items-toggle : center;
    --n-tabs-title-justify-content : center;
    --n-tabs-title-align-items : center;
    --n-tabs-title-direction : row;
    --n-tabs-title-gap : 10px;
    --n-tabs-title-padding-top : var(--n-tabs-default-padding-block);
    --n-tabs-title-padding-right : var(--n-tabs-default-padding-inline);
    --n-tabs-title-padding-bottom : var(--n-tabs-default-padding-block);
    --n-tabs-title-padding-left : var(--n-tabs-default-padding-inline);
    --n-tabs-title-border-radius : initial;
    --n-tabs-title-transition : 0.3s;
    --n-tabs-icon-color : var(--e-global-color-secondary, var(--n-tabs-color-secondary-fallback));
    --n-tabs-icon-color-hover : var(--n-tabs-title-color-hover);
    --n-tabs-icon-color-active : #fff;
    --n-tabs-icon-gap : 5px;
    width : 100%;
    max-width : 100%;
    }
    .elementor-widget-n-tabs .e-n-tabs {
    display : var(--n-tabs-display);
    flex-direction : var(--n-tabs-direction);
    gap : var(--n-tabs-gap);
    text-align : left;
    }
    .elementor-widget-n-tabs .e-n-tabs-heading {
    display : var(--n-tabs-heading-display);
    flex-basis : var(--n-tabs-heading-width);
    flex-direction : var(--n-tabs-heading-direction);
    flex-shrink : 0;
    justify-content : var(--n-tabs-heading-justify-content);
    gap : var(--n-tabs-title-gap);
    }
    .elementor-widget-n-tabs .e-n-tabs-content {
    flex-grow : 1;
    border-radius : var(--n-tabs-content-border-radius);
    }
    .elementor-widget-n-tabs .e-n-tab-title {
    display : flex;
    align-items : var(--n-tabs-title-align-items-toggle, var(--n-tabs-title-align-items));
    flex-direction : var(--n-tabs-title-direction);
    justify-content : var(--n-tabs-title-justify-content-toggle, var(--n-tabs-title-justify-content));
    gap : var(--n-tabs-icon-gap);
    border-width : var(--n-tabs-border-width);
    position : relative;
    cursor : pointer;
    outline : none;
    padding : var(--n-tabs-title-padding-top) var(--n-tabs-title-padding-right) var(--n-tabs-title-padding-bottom) var(--n-tabs-title-padding-left);
    border-radius : var(--n-tabs-title-border-radius);
    height : var(--n-tabs-title-height);
    width : var(--n-tabs-title-width);
    }
    .elementor-widget-n-tabs .e-n-tab-title span i, .elementor-widget-n-tabs .e-n-tab-title span svg {
    transition : color var(--n-tabs-title-transition), fill var(--n-tabs-title-transition);
    }
    .elementor-widget-n-tabs .e-n-tab-title-text {
    display : flex;
    align-items : center;
    font-size : var(--n-tabs-title-font-size);
    }
    .elementor-widget-n-tabs .e-n-tab-title .e-n-tab-icon {
    display : flex;
    align-items : center;
    flex-direction : column;
    overflow : hidden;
    }
    .elementor-widget-n-tabs .e-n-tab-title .e-n-tab-icon i {
    font-size : var(--n-tabs-icon-size, var(--n-tabs-title-font-size));
    }
    .elementor-widget-n-tabs .e-n-tab-title .e-n-tab-icon svg {
    width : var(--n-tabs-icon-size, var(--n-tabs-title-font-size));
    height : var(--n-tabs-icon-size, var(--n-tabs-title-font-size));
    }
    .elementor-widget-n-tabs .e-n-tab-title .e-n-tab-icon:empty {
    display : none;
    }
    .elementor-widget-n-tabs .e-n-tab-title:not(.e-active) {
    background-color : #f1f2f3;
    }
    .elementor-widget-n-tabs .e-n-tab-title:not(.e-active) .e-n-tab-icon i:last-child, .elementor-widget-n-tabs .e-n-tab-title:not(.e-active) .e-n-tab-icon svg:last-child {
    transform : translateY(-100vh);
    height : 0;
    opacity : 0;
    }
    .elementor-widget-n-tabs .e-n-tab-title:not(.e-active).e-normal:hover, .elementor-widget-n-tabs .e-n-tab-title:not(.e-active).e-normal:hover a {
    color : var(--n-tabs-title-color-hover);
    }
    .elementor-widget-n-tabs .e-n-tab-title:not(.e-active).e-normal:hover .e-n-tab-icon i {
    color : var(--n-tabs-icon-color-hover);
    }
    .elementor-widget-n-tabs .e-n-tab-title:not(.e-active).e-normal:hover .e-n-tab-icon svg {
    fill : var(--n-tabs-icon-color-hover);
    }
    .elementor-widget-n-tabs .e-n-tab-title.e-active, .elementor-widget-n-tabs .e-n-tab-title.e-active a {
    color : var(--n-tabs-title-color-active);
    }
    .elementor-widget-n-tabs .e-n-tab-title.e-active .e-n-tab-icon i {
    color : var(--n-tabs-icon-color-active);
    }
    .elementor-widget-n-tabs .e-n-tab-title.e-active .e-n-tab-icon svg {
    fill : var(--n-tabs-icon-color-active);
    }
    .elementor-widget-n-tabs .e-n-tab-title.e-active .e-n-tab-icon i:first-child, .elementor-widget-n-tabs .e-n-tab-title.e-active .e-n-tab-icon svg:first-child {
    transform : translateY(-100vh);
    height : 0;
    opacity : 0;
    }
    .elementor-widget-n-tabs .e-n-tab-title.e-active[class*="elementor-animation-"]:active, .elementor-widget-n-tabs .e-n-tab-title.e-active[class*="elementor-animation-"]:focus, .elementor-widget-n-tabs .e-n-tab-title.e-active[class*="elementor-animation-"]:hover {
    transform : none;
    animation : initial;
    }
    .elementor-widget-n-tabs .e-n-tabs-content .e-collapse {
    user-select : none;
    }
    .elementor-widget-n-tabs .e-n-tabs-content .e-collapse:not(:first-child) {
    margin-top : var(--n-tabs-title-gap);
    }
    .elementor-widget-n-tabs .e-n-tabs-content .e-collapse.e-active {
    margin-bottom : var(--n-tabs-gap);
    }
    .elementor-widget-n-tabs .e-n-tabs-content > .e-con:not(.e-active) {
    display : none;
    }
    :is(.elementor .elementor-element.elementor-widget-n-tabs > .elementor-widget-container > .e-n-tabs > .e-n-tabs-heading .e-n-tab-title, .elementor .elementor-element.elementor-widget-n-tabs > .elementor-widget-container > .e-n-tabs > .e-n-tabs-content .e-n-tab-title).e-normal:hover {
    background-image : none;
    }
    :is(.elementor .elementor-element.elementor-widget-n-tabs > .elementor-widget-container > .e-n-tabs > .e-n-tabs-heading .e-n-tab-title, .elementor .elementor-element.elementor-widget-n-tabs > .elementor-widget-container > .e-n-tabs > .e-n-tabs-content .e-n-tab-title).e-active {
    background-image : none;
    }
    @media (min-width: 768px) {
    .e-n-tabs-mobile > .elementor-widget-container > .e-n-tabs > .e-n-tabs-content > .e-collapse {
    display : none;
    }
    }
    @media (max-width: 767px) {
    .e-n-tabs-mobile > .elementor-widget-container > .e-n-tabs > .e-n-tabs-heading {
    display : none;
    }
    }
    @media {
    .e-n-tabs-mobile_extra > .elementor-widget-container > .e-n-tabs > .e-n-tabs-content > .e-collapse {
    display : none;
    }
    }
    @media {
    .e-n-tabs-mobile_extra > .elementor-widget-container > .e-n-tabs > .e-n-tabs-heading {
    display : none;
    }
    }
    @media (min-width: 1025px) {
    .e-n-tabs-tablet > .elementor-widget-container > .e-n-tabs > .e-n-tabs-content > .e-collapse {
    display : none;
    }
    }
    @media (max-width: 1024px) {
    .e-n-tabs-tablet > .elementor-widget-container > .e-n-tabs > .e-n-tabs-heading {
    display : none;
    }
    }
    @media {
    .e-n-tabs-tablet_extra > .elementor-widget-container > .e-n-tabs > .e-n-tabs-content > .e-collapse {
    display : none;
    }
    }
    @media {
    .e-n-tabs-tablet_extra > .elementor-widget-container > .e-n-tabs > .e-n-tabs-heading {
    display : none;
    }
    }
    @media (min-width: 1025px) {
    .e-n-tabs-laptop > .elementor-widget-container > .e-n-tabs > .e-n-tabs-content > .e-collapse {
    display : none;
    }
    }
    @media {
    .e-n-tabs-laptop > .elementor-widget-container > .e-n-tabs > .e-n-tabs-heading {
    display : none;
    }
    }
    .elementor-accordion {
    text-align : left;
    }
    .elementor-accordion .elementor-accordion-item {
    border : #d5d8dc solid 1px;
    }
    .elementor-accordion .elementor-accordion-item + .elementor-accordion-item {
    border-top : none;
    }
    .elementor-accordion .elementor-tab-title {
    margin : 0;
    padding : 15px 20px;
    font-weight : 700;
    line-height : 1;
    cursor : pointer;
    outline : none;
    }
    .elementor-accordion .elementor-tab-title .elementor-accordion-icon {
    display : inline-block;
    width : 1.5em;
    }
    .elementor-accordion .elementor-tab-title .elementor-accordion-icon svg {
    width : 1em;
    height : 1em;
    }
    .elementor-accordion .elementor-tab-title .elementor-accordion-icon.elementor-accordion-icon-right {
    float : right;
    text-align : right;
    }
    .elementor-accordion .elementor-tab-title .elementor-accordion-icon.elementor-accordion-icon-left {
    float : left;
    text-align : left;
    }
    .elementor-accordion .elementor-tab-title .elementor-accordion-icon .elementor-accordion-icon-closed {
    display : block;
    }
    .elementor-accordion .elementor-tab-title .elementor-accordion-icon .elementor-accordion-icon-opened, .elementor-accordion .elementor-tab-title.elementor-active .elementor-accordion-icon-closed {
    display : none;
    }
    .elementor-accordion .elementor-tab-title.elementor-active .elementor-accordion-icon-opened {
    display : block;
    }
    .elementor-accordion .elementor-tab-content {
    display : none;
    padding : 15px 20px;
    border-top : 1px solid #d5d8dc;
    }
    @media (max-width: 767px) {
    .elementor-accordion .elementor-tab-title {
    padding : 12px 15px;
    }
    .elementor-accordion .elementor-tab-title .elementor-accordion-icon {
    width : 1.2em;
    }
    .elementor-accordion .elementor-tab-content {
    padding : 7px 15px;
    }
    }
    .e-con-inner > .elementor-widget-accordion, .e-con > .elementor-widget-accordion {
    --flex-grow : var(--container-widget-flex-grow);
    }
    .elementor-alert {
    padding : 15px;
    border-left : 5px solid transparent;
    position : relative;
    text-align : left;
    }
    .elementor-alert .elementor-alert-title {
    display : block;
    font-weight : 700;
    }
    .elementor-alert .elementor-alert-description {
    font-size : 13px;
    }
    .elementor-alert button.elementor-alert-dismiss {
    position : absolute;
    right : var(--dismiss-icon-horizontal-position, 10px);
    top : var(--dismiss-icon-vertical-position, 10px);
    padding : 3px;
    font-size : var(--dismiss-icon-size, 20px);
    line-height : 1;
    background : transparent;
    color : var(--dismiss-icon-normal-color, inherit);
    border : none;
    cursor : pointer;
    transition-duration : var(--dismiss-icon-hover-transition-duration, 0.3s);
    }
    .elementor-alert button.elementor-alert-dismiss:hover {
    color : var(--dismiss-icon-hover-color, inherit);
    }
    .elementor-alert button.elementor-alert-dismiss svg {
    width : var(--dismiss-icon-size, 20px);
    height : var(--dismiss-icon-size, 20px);
    fill : var(--dismiss-icon-normal-color, currentColor);
    transition-duration : var(--dismiss-icon-hover-transition-duration, 0.3s);
    }
    .elementor-alert button.elementor-alert-dismiss svg:hover {
    fill : var(--dismiss-icon-hover-color, currentColor);
    }
    .elementor-alert.elementor-alert-info {
    color : #31708f;
    background-color : #d9edf7;
    border-color : #bcdff1;
    }
    .elementor-alert.elementor-alert-success {
    color : #3c763d;
    background-color : #dff0d8;
    border-color : #cae6be;
    }
    .elementor-alert.elementor-alert-warning {
    color : #8a6d3b;
    background-color : #fcf8e3;
    border-color : #f9f0c3;
    }
    .elementor-alert.elementor-alert-danger {
    color : #a94442;
    background-color : #f2dede;
    border-color : #e8c4c4;
    }
    @media (max-width: 767px) {
    .elementor-alert {
    padding : 10px;
    }
    .elementor-alert button.elementor-alert-dismiss {
    right : 7px;
    top : 7px;
    }
    }
    .elementor-counter .elementor-counter-number-wrapper {
    display : flex;
    font-size : 69px;
    font-weight : 600;
    line-height : 1;
    }
    .elementor-counter .elementor-counter-number-prefix, .elementor-counter .elementor-counter-number-suffix {
    flex-grow : 1;
    white-space : pre-wrap;
    }
    .elementor-counter .elementor-counter-number-prefix {
    text-align : right;
    }
    .elementor-counter .elementor-counter-number-suffix {
    text-align : left;
    }
    .elementor-counter .elementor-counter-title {
    text-align : center;
    font-size : 19px;
    font-weight : 400;
    line-height : 2.5;
    }
    .elementor-widget-divider {
    --divider-border-style : none;
    --divider-border-width : 1px;
    --divider-color : #0c0d0e;
    --divider-icon-size : 20px;
    --divider-element-spacing : 10px;
    --divider-pattern-height : 24px;
    --divider-pattern-size : 20px;
    --divider-pattern-url : none;
    --divider-pattern-repeat : repeat-x;
    }
    .elementor-widget-divider .elementor-divider {
    display : flex;
    }
    .elementor-widget-divider .elementor-divider__text {
    font-size : 15px;
    line-height : 1;
    max-width : 95%;
    }
    .elementor-widget-divider .elementor-divider__element {
    margin : 0 var(--divider-element-spacing);
    flex-shrink : 0;
    }
    .elementor-widget-divider .elementor-icon {
    font-size : var(--divider-icon-size);
    }
    .elementor-widget-divider .elementor-divider-separator {
    display : flex;
    margin : 0;
    direction : ltr;
    }
    .elementor-widget-divider--view-line_icon .elementor-divider-separator, .elementor-widget-divider--view-line_text .elementor-divider-separator {
    align-items : center;
    }
    .elementor-widget-divider--view-line_icon .elementor-divider-separator:after, .elementor-widget-divider--view-line_icon .elementor-divider-separator:before, .elementor-widget-divider--view-line_text .elementor-divider-separator:after, .elementor-widget-divider--view-line_text .elementor-divider-separator:before {
    display : block;
    content : "";
    border-bottom : 0;
    flex-grow : 1;
    border-top : var(--divider-border-width) var(--divider-border-style) var(--divider-color);
    }
    .elementor-widget-divider--element-align-left .elementor-divider .elementor-divider-separator > .elementor-divider__svg:first-of-type {
    flex-grow : 0;
    flex-shrink : 100;
    }
    .elementor-widget-divider--element-align-left .elementor-divider-separator:before {
    content : none;
    }
    .elementor-widget-divider--element-align-left .elementor-divider__element {
    margin-left : 0;
    }
    .elementor-widget-divider--element-align-right .elementor-divider .elementor-divider-separator > .elementor-divider__svg:last-of-type {
    flex-grow : 0;
    flex-shrink : 100;
    }
    .elementor-widget-divider--element-align-right .elementor-divider-separator:after {
    content : none;
    }
    .elementor-widget-divider--element-align-right .elementor-divider__element {
    margin-right : 0;
    }
    .elementor-widget-divider:not(.elementor-widget-divider--view-line_text):not(.elementor-widget-divider--view-line_icon) .elementor-divider-separator {
    border-top : var(--divider-border-width) var(--divider-border-style) var(--divider-color);
    }
    .elementor-widget-divider--separator-type-pattern {
    --divider-border-style : none;
    }
    .elementor-widget-divider--separator-type-pattern.elementor-widget-divider--view-line .elementor-divider-separator, .elementor-widget-divider--separator-type-pattern:not(.elementor-widget-divider--view-line) .elementor-divider-separator:after, .elementor-widget-divider--separator-type-pattern:not(.elementor-widget-divider--view-line) .elementor-divider-separator:before, .elementor-widget-divider--separator-type-pattern:not([class*="elementor-widget-divider--view"]) .elementor-divider-separator {
    width : 100%;
    min-height : var(--divider-pattern-height);
    mask-size : var(--divider-pattern-size) 100%;
    mask-repeat : var(--divider-pattern-repeat);
    background-color : #0c0d0e;
    mask-image : var(--divider-pattern-url);
    }
    .elementor-widget-divider--no-spacing {
    --divider-pattern-size : auto;
    }
    .elementor-widget-divider--bg-round {
    --divider-pattern-repeat : round;
    }
    .rtl .elementor-widget-divider .elementor-divider__text {
    direction : rtl;
    }
    .e-con-inner > .elementor-widget-divider, .e-con > .elementor-widget-divider {
    width : var(--container-widget-width, 100%);
    --flex-grow : var(--container-widget-flex-grow);
    }
    .elementor-image-gallery .gallery-item {
    display : inline-block;
    text-align : center;
    vertical-align : top;
    width : 100%;
    max-width : 100%;
    margin : 0 auto;
    }
    .elementor-image-gallery .gallery-item img {
    margin : 0 auto;
    }
    .elementor-image-gallery .gallery-item .gallery-caption {
    margin : 0;
    }
    .elementor-image-gallery figure img {
    display : block;
    }
    .elementor-image-gallery figure figcaption {
    width : 100%;
    }
    .gallery-spacing-custom .elementor-image-gallery .gallery-icon {
    padding : 0;
    }
    @media (min-width: 768px) {
    .elementor-image-gallery .gallery-columns-2 .gallery-item {
    max-width : 50%;
    }
    .elementor-image-gallery .gallery-columns-3 .gallery-item {
    max-width : 33.33%;
    }
    .elementor-image-gallery .gallery-columns-4 .gallery-item {
    max-width : 25%;
    }
    .elementor-image-gallery .gallery-columns-5 .gallery-item {
    max-width : 20%;
    }
    .elementor-image-gallery .gallery-columns-6 .gallery-item {
    max-width : 16.666%;
    }
    .elementor-image-gallery .gallery-columns-7 .gallery-item {
    max-width : 14.28%;
    }
    .elementor-image-gallery .gallery-columns-8 .gallery-item {
    max-width : 12.5%;
    }
    .elementor-image-gallery .gallery-columns-9 .gallery-item {
    max-width : 11.11%;
    }
    .elementor-image-gallery .gallery-columns-10 .gallery-item {
    max-width : 10%;
    }
    }
    @media (min-width: 480px) and (max-width: 767px) {
    .elementor-image-gallery .gallery.gallery-columns-2 .gallery-item, .elementor-image-gallery .gallery.gallery-columns-3 .gallery-item, .elementor-image-gallery .gallery.gallery-columns-4 .gallery-item, .elementor-image-gallery .gallery.gallery-columns-5 .gallery-item, .elementor-image-gallery .gallery.gallery-columns-6 .gallery-item, .elementor-image-gallery .gallery.gallery-columns-7 .gallery-item, .elementor-image-gallery .gallery.gallery-columns-8 .gallery-item, .elementor-image-gallery .gallery.gallery-columns-9 .gallery-item, .elementor-image-gallery .gallery.gallery-columns-10 .gallery-item {
    max-width : 50%;
    }
    }
    @media (max-width: 479px) {
    .elementor-image-gallery .gallery.gallery-columns-2 .gallery-item, .elementor-image-gallery .gallery.gallery-columns-3 .gallery-item, .elementor-image-gallery .gallery.gallery-columns-4 .gallery-item, .elementor-image-gallery .gallery.gallery-columns-5 .gallery-item, .elementor-image-gallery .gallery.gallery-columns-6 .gallery-item, .elementor-image-gallery .gallery.gallery-columns-7 .gallery-item, .elementor-image-gallery .gallery.gallery-columns-8 .gallery-item, .elementor-image-gallery .gallery.gallery-columns-9 .gallery-item, .elementor-image-gallery .gallery.gallery-columns-10 .gallery-item {
    max-width : 100%;
    }
    }
    .elementor-widget-google_maps .elementor-widget-container {
    overflow : hidden;
    }
    .elementor-widget-google_maps .elementor-custom-embed {
    line-height : 0;
    }
    .elementor-widget-google_maps iframe {
    height : 300px;
    }
    .elementor-heading-title {
    padding : 0;
    margin : 0;
    line-height : 1;
    }
    .elementor-widget-heading .elementor-heading-title[class*="elementor-size-"] > a {
    color : inherit;
    font-size : inherit;
    line-height : inherit;
    }
    .elementor-widget-heading .elementor-heading-title.elementor-size-small {
    font-size : 15px;
    }
    .elementor-widget-heading .elementor-heading-title.elementor-size-medium {
    font-size : 19px;
    }
    .elementor-widget-heading .elementor-heading-title.elementor-size-large {
    font-size : 29px;
    }
    .elementor-widget-heading .elementor-heading-title.elementor-size-xl {
    font-size : 39px;
    }
    .elementor-widget-heading .elementor-heading-title.elementor-size-xxl {
    font-size : 59px;
    }
    .elementor-widget-icon-box .elementor-icon-box-wrapper {
    display : block;
    text-align : center;
    }
    .elementor-widget-icon-box .elementor-icon-box-icon {
    margin-bottom : var(--icon-box-icon-margin, 15px);
    margin-right : auto;
    margin-left : auto;
    }
    @media (min-width: 768px) {
    .elementor-widget-icon-box.elementor-vertical-align-top .elementor-icon-box-wrapper {
    align-items : flex-start;
    }
    .elementor-widget-icon-box.elementor-vertical-align-middle .elementor-icon-box-wrapper {
    align-items : center;
    }
    .elementor-widget-icon-box.elementor-vertical-align-bottom .elementor-icon-box-wrapper {
    align-items : flex-end;
    }
    }
    .elementor-widget-icon-box.elementor-position-left .elementor-icon-box-wrapper, .elementor-widget-icon-box.elementor-position-right .elementor-icon-box-wrapper {
    display : flex;
    }
    .elementor-widget-icon-box.elementor-position-left .elementor-icon-box-icon, .elementor-widget-icon-box.elementor-position-right .elementor-icon-box-icon {
    display : inline-flex;
    flex : 0 0 auto;
    }
    .elementor-widget-icon-box.elementor-position-right .elementor-icon-box-wrapper {
    text-align : right;
    flex-direction : row-reverse;
    }
    .elementor-widget-icon-box.elementor-position-right .elementor-icon-box-icon {
    margin-left : var(--icon-box-icon-margin, 15px);
    margin-right : 0;
    margin-bottom : unset;
    }
    .elementor-widget-icon-box.elementor-position-left .elementor-icon-box-wrapper {
    text-align : left;
    flex-direction : row;
    }
    .elementor-widget-icon-box.elementor-position-left .elementor-icon-box-icon {
    margin-right : var(--icon-box-icon-margin, 15px);
    margin-left : 0;
    margin-bottom : unset;
    }
    .elementor-widget-icon-box.elementor-position-top .elementor-icon-box-wrapper {
    display : block;
    text-align : center;
    flex-direction : unset;
    }
    .elementor-widget-icon-box.elementor-position-top .elementor-icon-box-icon {
    margin-bottom : var(--icon-box-icon-margin, 15px);
    margin-right : auto;
    margin-left : auto;
    }
    @media {
    .elementor-widget-icon-box.elementor-widescreen-position-left .elementor-icon-box-wrapper, .elementor-widget-icon-box.elementor-widescreen-position-right .elementor-icon-box-wrapper {
    display : flex;
    }
    .elementor-widget-icon-box.elementor-widescreen-position-left .elementor-icon-box-icon, .elementor-widget-icon-box.elementor-widescreen-position-right .elementor-icon-box-icon {
    display : inline-flex;
    flex : 0 0 auto;
    }
    .elementor-widget-icon-box.elementor-widescreen-position-right .elementor-icon-box-wrapper {
    text-align : right;
    flex-direction : row-reverse;
    }
    .elementor-widget-icon-box.elementor-widescreen-position-right .elementor-icon-box-icon {
    margin-left : var(--icon-box-icon-margin, 15px);
    margin-right : 0;
    margin-bottom : unset;
    }
    .elementor-widget-icon-box.elementor-widescreen-position-left .elementor-icon-box-wrapper {
    text-align : left;
    flex-direction : row;
    }
    .elementor-widget-icon-box.elementor-widescreen-position-left .elementor-icon-box-icon {
    margin-right : var(--icon-box-icon-margin, 15px);
    margin-left : 0;
    margin-bottom : unset;
    }
    .elementor-widget-icon-box.elementor-widescreen-position-top .elementor-icon-box-wrapper {
    display : block;
    text-align : center;
    flex-direction : unset;
    }
    .elementor-widget-icon-box.elementor-widescreen-position-top .elementor-icon-box-icon {
    margin-bottom : var(--icon-box-icon-margin, 15px);
    margin-right : auto;
    margin-left : auto;
    }
    }
    @media {
    .elementor-widget-icon-box.elementor-laptop-position-left .elementor-icon-box-wrapper, .elementor-widget-icon-box.elementor-laptop-position-right .elementor-icon-box-wrapper {
    display : flex;
    }
    .elementor-widget-icon-box.elementor-laptop-position-left .elementor-icon-box-icon, .elementor-widget-icon-box.elementor-laptop-position-right .elementor-icon-box-icon {
    display : inline-flex;
    flex : 0 0 auto;
    }
    .elementor-widget-icon-box.elementor-laptop-position-right .elementor-icon-box-wrapper {
    text-align : right;
    flex-direction : row-reverse;
    }
    .elementor-widget-icon-box.elementor-laptop-position-right .elementor-icon-box-icon {
    margin-left : var(--icon-box-icon-margin, 15px);
    margin-right : 0;
    margin-bottom : unset;
    }
    .elementor-widget-icon-box.elementor-laptop-position-left .elementor-icon-box-wrapper {
    text-align : left;
    flex-direction : row;
    }
    .elementor-widget-icon-box.elementor-laptop-position-left .elementor-icon-box-icon {
    margin-right : var(--icon-box-icon-margin, 15px);
    margin-left : 0;
    margin-bottom : unset;
    }
    .elementor-widget-icon-box.elementor-laptop-position-top .elementor-icon-box-wrapper {
    display : block;
    text-align : center;
    flex-direction : unset;
    }
    .elementor-widget-icon-box.elementor-laptop-position-top .elementor-icon-box-icon {
    margin-bottom : var(--icon-box-icon-margin, 15px);
    margin-right : auto;
    margin-left : auto;
    }
    }
    @media {
    .elementor-widget-icon-box.elementor-tablet_extra-position-left .elementor-icon-box-wrapper, .elementor-widget-icon-box.elementor-tablet_extra-position-right .elementor-icon-box-wrapper {
    display : flex;
    }
    .elementor-widget-icon-box.elementor-tablet_extra-position-left .elementor-icon-box-icon, .elementor-widget-icon-box.elementor-tablet_extra-position-right .elementor-icon-box-icon {
    display : inline-flex;
    flex : 0 0 auto;
    }
    .elementor-widget-icon-box.elementor-tablet_extra-position-right .elementor-icon-box-wrapper {
    text-align : right;
    flex-direction : row-reverse;
    }
    .elementor-widget-icon-box.elementor-tablet_extra-position-right .elementor-icon-box-icon {
    margin-left : var(--icon-box-icon-margin, 15px);
    margin-right : 0;
    margin-bottom : unset;
    }
    .elementor-widget-icon-box.elementor-tablet_extra-position-left .elementor-icon-box-wrapper {
    text-align : left;
    flex-direction : row;
    }
    .elementor-widget-icon-box.elementor-tablet_extra-position-left .elementor-icon-box-icon {
    margin-right : var(--icon-box-icon-margin, 15px);
    margin-left : 0;
    margin-bottom : unset;
    }
    .elementor-widget-icon-box.elementor-tablet_extra-position-top .elementor-icon-box-wrapper {
    display : block;
    text-align : center;
    flex-direction : unset;
    }
    .elementor-widget-icon-box.elementor-tablet_extra-position-top .elementor-icon-box-icon {
    margin-bottom : var(--icon-box-icon-margin, 15px);
    margin-right : auto;
    margin-left : auto;
    }
    }
    @media (max-width: 1024px) {
    .elementor-widget-icon-box.elementor-tablet-position-left .elementor-icon-box-wrapper, .elementor-widget-icon-box.elementor-tablet-position-right .elementor-icon-box-wrapper {
    display : flex;
    }
    .elementor-widget-icon-box.elementor-tablet-position-left .elementor-icon-box-icon, .elementor-widget-icon-box.elementor-tablet-position-right .elementor-icon-box-icon {
    display : inline-flex;
    flex : 0 0 auto;
    }
    .elementor-widget-icon-box.elementor-tablet-position-right .elementor-icon-box-wrapper {
    text-align : right;
    flex-direction : row-reverse;
    }
    .elementor-widget-icon-box.elementor-tablet-position-right .elementor-icon-box-icon {
    margin-left : var(--icon-box-icon-margin, 15px);
    margin-right : 0;
    margin-bottom : unset;
    }
    .elementor-widget-icon-box.elementor-tablet-position-left .elementor-icon-box-wrapper {
    text-align : left;
    flex-direction : row;
    }
    .elementor-widget-icon-box.elementor-tablet-position-left .elementor-icon-box-icon {
    margin-right : var(--icon-box-icon-margin, 15px);
    margin-left : 0;
    margin-bottom : unset;
    }
    .elementor-widget-icon-box.elementor-tablet-position-top .elementor-icon-box-wrapper {
    display : block;
    text-align : center;
    flex-direction : unset;
    }
    .elementor-widget-icon-box.elementor-tablet-position-top .elementor-icon-box-icon {
    margin-bottom : var(--icon-box-icon-margin, 15px);
    margin-right : auto;
    margin-left : auto;
    }
    }
    @media {
    .elementor-widget-icon-box.elementor-mobile_extra-position-left .elementor-icon-box-wrapper, .elementor-widget-icon-box.elementor-mobile_extra-position-right .elementor-icon-box-wrapper {
    display : flex;
    }
    .elementor-widget-icon-box.elementor-mobile_extra-position-left .elementor-icon-box-icon, .elementor-widget-icon-box.elementor-mobile_extra-position-right .elementor-icon-box-icon {
    display : inline-flex;
    flex : 0 0 auto;
    }
    .elementor-widget-icon-box.elementor-mobile_extra-position-right .elementor-icon-box-wrapper {
    text-align : right;
    flex-direction : row-reverse;
    }
    .elementor-widget-icon-box.elementor-mobile_extra-position-right .elementor-icon-box-icon {
    margin-left : var(--icon-box-icon-margin, 15px);
    margin-right : 0;
    margin-bottom : unset;
    }
    .elementor-widget-icon-box.elementor-mobile_extra-position-left .elementor-icon-box-wrapper {
    text-align : left;
    flex-direction : row;
    }
    .elementor-widget-icon-box.elementor-mobile_extra-position-left .elementor-icon-box-icon {
    margin-right : var(--icon-box-icon-margin, 15px);
    margin-left : 0;
    margin-bottom : unset;
    }
    .elementor-widget-icon-box.elementor-mobile_extra-position-top .elementor-icon-box-wrapper {
    display : block;
    text-align : center;
    flex-direction : unset;
    }
    .elementor-widget-icon-box.elementor-mobile_extra-position-top .elementor-icon-box-icon {
    margin-bottom : var(--icon-box-icon-margin, 15px);
    margin-right : auto;
    margin-left : auto;
    }
    }
    @media (max-width: 767px) {
    .elementor-widget-icon-box.elementor-mobile-position-left .elementor-icon-box-wrapper, .elementor-widget-icon-box.elementor-mobile-position-right .elementor-icon-box-wrapper {
    display : flex;
    }
    .elementor-widget-icon-box.elementor-mobile-position-left .elementor-icon-box-icon, .elementor-widget-icon-box.elementor-mobile-position-right .elementor-icon-box-icon {
    display : inline-flex;
    flex : 0 0 auto;
    }
    .elementor-widget-icon-box.elementor-mobile-position-right .elementor-icon-box-wrapper {
    text-align : right;
    flex-direction : row-reverse;
    }
    .elementor-widget-icon-box.elementor-mobile-position-right .elementor-icon-box-icon {
    margin-left : var(--icon-box-icon-margin, 15px);
    margin-right : 0;
    margin-bottom : unset;
    }
    .elementor-widget-icon-box.elementor-mobile-position-left .elementor-icon-box-wrapper {
    text-align : left;
    flex-direction : row;
    }
    .elementor-widget-icon-box.elementor-mobile-position-left .elementor-icon-box-icon {
    margin-right : var(--icon-box-icon-margin, 15px);
    margin-left : 0;
    margin-bottom : unset;
    }
    .elementor-widget-icon-box.elementor-mobile-position-top .elementor-icon-box-wrapper {
    display : block;
    text-align : center;
    flex-direction : unset;
    }
    .elementor-widget-icon-box.elementor-mobile-position-top .elementor-icon-box-icon {
    margin-bottom : var(--icon-box-icon-margin, 15px);
    margin-right : auto;
    margin-left : auto;
    }
    .elementor-widget-icon-box.elementor-position-left .elementor-icon-box-icon, .elementor-widget-icon-box.elementor-position-right .elementor-icon-box-icon {
    display : block;
    flex : unset;
    }
    }
    .elementor-widget-icon-box .elementor-icon-box-title a {
    color : inherit;
    }
    .elementor-widget-icon-box .elementor-icon-box-content {
    flex-grow : 1;
    }
    .elementor-widget-icon-box .elementor-icon-box-description {
    margin : 0;
    }
    .elementor-widget.elementor-icon-list--layout-inline .elementor-widget-container {
    overflow : hidden;
    }
    .elementor-widget .elementor-icon-list-items.elementor-inline-items {
    margin-right : -8px;
    margin-left : -8px;
    }
    .elementor-widget .elementor-icon-list-items.elementor-inline-items .elementor-icon-list-item {
    margin-right : 8px;
    margin-left : 8px;
    }
    .elementor-widget .elementor-icon-list-items.elementor-inline-items .elementor-icon-list-item:after {
    width : auto;
    left : auto;
    right : auto;
    position : relative;
    height : 100%;
    border-top : 0;
    border-bottom : 0;
    border-right : 0;
    border-left-width : 1px;
    border-style : solid;
    right : -8px;
    }
    .elementor-widget .elementor-icon-list-items {
    list-style-type : none;
    margin : 0;
    padding : 0;
    }
    .elementor-widget .elementor-icon-list-item {
    margin : 0;
    padding : 0;
    position : relative;
    }
    .elementor-widget .elementor-icon-list-item:after {
    position : absolute;
    bottom : 0;
    width : 100%;
    }
    .elementor-widget .elementor-icon-list-item, .elementor-widget .elementor-icon-list-item a {
    display : flex;
    font-size : inherit;
    align-items : var(--icon-vertical-align, center);
    }
    .elementor-widget .elementor-icon-list-icon + .elementor-icon-list-text {
    align-self : center;
    padding-left : 5px;
    }
    .elementor-widget .elementor-icon-list-icon {
    display : flex;
    position : relative;
    top : var(--icon-vertical-offset, initial);
    }
    .elementor-widget .elementor-icon-list-icon svg {
    width : var(--e-icon-list-icon-size, 1em);
    height : var(--e-icon-list-icon-size, 1em);
    }
    .elementor-widget .elementor-icon-list-icon i {
    width : 1.25em;
    }
    .elementor-widget.elementor-list-item-link-full_width a {
    width : 100%;
    }
    .elementor-widget.elementor-align-center .elementor-icon-list-item, .elementor-widget.elementor-align-center .elementor-icon-list-item a {
    justify-content : center;
    }
    .elementor-widget.elementor-align-center .elementor-icon-list-item:after {
    margin : auto;
    }
    .elementor-widget.elementor-align-center .elementor-inline-items {
    justify-content : center;
    }
    .elementor-widget.elementor-align-left .elementor-icon-list-item, .elementor-widget.elementor-align-left .elementor-icon-list-item a {
    justify-content : flex-start;
    text-align : left;
    }
    .elementor-widget.elementor-align-left .elementor-inline-items {
    justify-content : flex-start;
    }
    .elementor-widget.elementor-align-right .elementor-icon-list-item, .elementor-widget.elementor-align-right .elementor-icon-list-item a {
    justify-content : flex-end;
    text-align : right;
    }
    .elementor-widget.elementor-align-right .elementor-icon-list-items {
    justify-content : flex-end;
    }
    .elementor-widget:not(.elementor-align-right) .elementor-icon-list-item:after {
    left : 0;
    }
    .elementor-widget:not(.elementor-align-left) .elementor-icon-list-item:after {
    right : 0;
    }
    @media {
    .elementor-widget.elementor-widescreen-align-center .elementor-icon-list-item, .elementor-widget.elementor-widescreen-align-center .elementor-icon-list-item a {
    justify-content : center;
    }
    .elementor-widget.elementor-widescreen-align-center .elementor-icon-list-item:after {
    margin : auto;
    }
    .elementor-widget.elementor-widescreen-align-center .elementor-inline-items {
    justify-content : center;
    }
    .elementor-widget.elementor-widescreen-align-left .elementor-icon-list-item, .elementor-widget.elementor-widescreen-align-left .elementor-icon-list-item a {
    justify-content : flex-start;
    text-align : left;
    }
    .elementor-widget.elementor-widescreen-align-left .elementor-inline-items {
    justify-content : flex-start;
    }
    .elementor-widget.elementor-widescreen-align-right .elementor-icon-list-item, .elementor-widget.elementor-widescreen-align-right .elementor-icon-list-item a {
    justify-content : flex-end;
    text-align : right;
    }
    .elementor-widget.elementor-widescreen-align-right .elementor-icon-list-items {
    justify-content : flex-end;
    }
    .elementor-widget:not(.elementor-widescreen-align-right) .elementor-icon-list-item:after {
    left : 0;
    }
    .elementor-widget:not(.elementor-widescreen-align-left) .elementor-icon-list-item:after {
    right : 0;
    }
    }
    @media {
    .elementor-widget.elementor-laptop-align-center .elementor-icon-list-item, .elementor-widget.elementor-laptop-align-center .elementor-icon-list-item a {
    justify-content : center;
    }
    .elementor-widget.elementor-laptop-align-center .elementor-icon-list-item:after {
    margin : auto;
    }
    .elementor-widget.elementor-laptop-align-center .elementor-inline-items {
    justify-content : center;
    }
    .elementor-widget.elementor-laptop-align-left .elementor-icon-list-item, .elementor-widget.elementor-laptop-align-left .elementor-icon-list-item a {
    justify-content : flex-start;
    text-align : left;
    }
    .elementor-widget.elementor-laptop-align-left .elementor-inline-items {
    justify-content : flex-start;
    }
    .elementor-widget.elementor-laptop-align-right .elementor-icon-list-item, .elementor-widget.elementor-laptop-align-right .elementor-icon-list-item a {
    justify-content : flex-end;
    text-align : right;
    }
    .elementor-widget.elementor-laptop-align-right .elementor-icon-list-items {
    justify-content : flex-end;
    }
    .elementor-widget:not(.elementor-laptop-align-right) .elementor-icon-list-item:after {
    left : 0;
    }
    .elementor-widget:not(.elementor-laptop-align-left) .elementor-icon-list-item:after {
    right : 0;
    }
    }
    @media {
    .elementor-widget.elementor-tablet_extra-align-center .elementor-icon-list-item, .elementor-widget.elementor-tablet_extra-align-center .elementor-icon-list-item a {
    justify-content : center;
    }
    .elementor-widget.elementor-tablet_extra-align-center .elementor-icon-list-item:after {
    margin : auto;
    }
    .elementor-widget.elementor-tablet_extra-align-center .elementor-inline-items {
    justify-content : center;
    }
    .elementor-widget.elementor-tablet_extra-align-left .elementor-icon-list-item, .elementor-widget.elementor-tablet_extra-align-left .elementor-icon-list-item a {
    justify-content : flex-start;
    text-align : left;
    }
    .elementor-widget.elementor-tablet_extra-align-left .elementor-inline-items {
    justify-content : flex-start;
    }
    .elementor-widget.elementor-tablet_extra-align-right .elementor-icon-list-item, .elementor-widget.elementor-tablet_extra-align-right .elementor-icon-list-item a {
    justify-content : flex-end;
    text-align : right;
    }
    .elementor-widget.elementor-tablet_extra-align-right .elementor-icon-list-items {
    justify-content : flex-end;
    }
    .elementor-widget:not(.elementor-tablet_extra-align-right) .elementor-icon-list-item:after {
    left : 0;
    }
    .elementor-widget:not(.elementor-tablet_extra-align-left) .elementor-icon-list-item:after {
    right : 0;
    }
    }
    @media (max-width: 1024px) {
    .elementor-widget.elementor-tablet-align-center .elementor-icon-list-item, .elementor-widget.elementor-tablet-align-center .elementor-icon-list-item a {
    justify-content : center;
    }
    .elementor-widget.elementor-tablet-align-center .elementor-icon-list-item:after {
    margin : auto;
    }
    .elementor-widget.elementor-tablet-align-center .elementor-inline-items {
    justify-content : center;
    }
    .elementor-widget.elementor-tablet-align-left .elementor-icon-list-item, .elementor-widget.elementor-tablet-align-left .elementor-icon-list-item a {
    justify-content : flex-start;
    text-align : left;
    }
    .elementor-widget.elementor-tablet-align-left .elementor-inline-items {
    justify-content : flex-start;
    }
    .elementor-widget.elementor-tablet-align-right .elementor-icon-list-item, .elementor-widget.elementor-tablet-align-right .elementor-icon-list-item a {
    justify-content : flex-end;
    text-align : right;
    }
    .elementor-widget.elementor-tablet-align-right .elementor-icon-list-items {
    justify-content : flex-end;
    }
    .elementor-widget:not(.elementor-tablet-align-right) .elementor-icon-list-item:after {
    left : 0;
    }
    .elementor-widget:not(.elementor-tablet-align-left) .elementor-icon-list-item:after {
    right : 0;
    }
    }
    @media {
    .elementor-widget.elementor-mobile_extra-align-center .elementor-icon-list-item, .elementor-widget.elementor-mobile_extra-align-center .elementor-icon-list-item a {
    justify-content : center;
    }
    .elementor-widget.elementor-mobile_extra-align-center .elementor-icon-list-item:after {
    margin : auto;
    }
    .elementor-widget.elementor-mobile_extra-align-center .elementor-inline-items {
    justify-content : center;
    }
    .elementor-widget.elementor-mobile_extra-align-left .elementor-icon-list-item, .elementor-widget.elementor-mobile_extra-align-left .elementor-icon-list-item a {
    justify-content : flex-start;
    text-align : left;
    }
    .elementor-widget.elementor-mobile_extra-align-left .elementor-inline-items {
    justify-content : flex-start;
    }
    .elementor-widget.elementor-mobile_extra-align-right .elementor-icon-list-item, .elementor-widget.elementor-mobile_extra-align-right .elementor-icon-list-item a {
    justify-content : flex-end;
    text-align : right;
    }
    .elementor-widget.elementor-mobile_extra-align-right .elementor-icon-list-items {
    justify-content : flex-end;
    }
    .elementor-widget:not(.elementor-mobile_extra-align-right) .elementor-icon-list-item:after {
    left : 0;
    }
    .elementor-widget:not(.elementor-mobile_extra-align-left) .elementor-icon-list-item:after {
    right : 0;
    }
    }
    @media (max-width: 767px) {
    .elementor-widget.elementor-mobile-align-center .elementor-icon-list-item, .elementor-widget.elementor-mobile-align-center .elementor-icon-list-item a {
    justify-content : center;
    }
    .elementor-widget.elementor-mobile-align-center .elementor-icon-list-item:after {
    margin : auto;
    }
    .elementor-widget.elementor-mobile-align-center .elementor-inline-items {
    justify-content : center;
    }
    .elementor-widget.elementor-mobile-align-left .elementor-icon-list-item, .elementor-widget.elementor-mobile-align-left .elementor-icon-list-item a {
    justify-content : flex-start;
    text-align : left;
    }
    .elementor-widget.elementor-mobile-align-left .elementor-inline-items {
    justify-content : flex-start;
    }
    .elementor-widget.elementor-mobile-align-right .elementor-icon-list-item, .elementor-widget.elementor-mobile-align-right .elementor-icon-list-item a {
    justify-content : flex-end;
    text-align : right;
    }
    .elementor-widget.elementor-mobile-align-right .elementor-icon-list-items {
    justify-content : flex-end;
    }
    .elementor-widget:not(.elementor-mobile-align-right) .elementor-icon-list-item:after {
    left : 0;
    }
    .elementor-widget:not(.elementor-mobile-align-left) .elementor-icon-list-item:after {
    right : 0;
    }
    }
    .elementor-widget-image {
    text-align : center;
    }
    .elementor-widget-image a {
    display : inline-block;
    }
    .elementor-widget-image a img[src$=".svg"] {
    width : 48px;
    }
    .elementor-widget-image img {
    vertical-align : middle;
    display : inline-block;
    }
    .elementor-widget-image-box .elementor-image-box-content {
    width : 100%;
    }
    @media (min-width: 768px) {
    .elementor-widget-image-box.elementor-position-left .elementor-image-box-wrapper, .elementor-widget-image-box.elementor-position-right .elementor-image-box-wrapper {
    display : flex;
    }
    .elementor-widget-image-box.elementor-position-right .elementor-image-box-wrapper {
    text-align : right;
    flex-direction : row-reverse;
    }
    .elementor-widget-image-box.elementor-position-left .elementor-image-box-wrapper {
    text-align : left;
    flex-direction : row;
    }
    .elementor-widget-image-box.elementor-position-top .elementor-image-box-img {
    margin : auto;
    }
    .elementor-widget-image-box.elementor-vertical-align-top .elementor-image-box-wrapper {
    align-items : flex-start;
    }
    .elementor-widget-image-box.elementor-vertical-align-middle .elementor-image-box-wrapper {
    align-items : center;
    }
    .elementor-widget-image-box.elementor-vertical-align-bottom .elementor-image-box-wrapper {
    align-items : flex-end;
    }
    }
    @media (max-width: 767px) {
    .elementor-widget-image-box .elementor-image-box-img {
    margin-left : auto !important ;
    margin-right : auto !important ;
    margin-bottom : 15px;
    }
    }
    .elementor-widget-image-box .elementor-image-box-img {
    display : inline-block;
    }
    .elementor-widget-image-box .elementor-image-box-title a {
    color : inherit;
    }
    .elementor-widget-image-box .elementor-image-box-wrapper {
    text-align : center;
    }
    .elementor-widget-image-box .elementor-image-box-description {
    margin : 0;
    }
    .elementor-widget-image-carousel .swiper, .elementor-widget-image-carousel .swiper-container {
    position : static;
    }
    .elementor-widget-image-carousel .swiper-container .swiper-slide figure, .elementor-widget-image-carousel .swiper .swiper-slide figure {
    line-height : inherit;
    }
    .elementor-widget-image-carousel .swiper-slide {
    text-align : center;
    }
    .elementor-image-carousel-wrapper:not(.swiper-container-initialized) .swiper-slide, .elementor-image-carousel-wrapper:not(.swiper-initialized) .swiper-slide {
    max-width : calc(100% / var(--e-image-carousel-slides-to-show, 3));
    }
    body.elementor-page .elementor-widget-menu-anchor {
    margin-bottom : 0;
    }
    .elementor-widget-progress {
    text-align : left;
    }
    .elementor-progress-wrapper {
    position : relative;
    background-color : #eee;
    color : #fff;
    height : 100%;
    border-radius : 2px;
    }
    .elementor-progress-bar {
    display : flex;
    background-color : #69727d;
    width : 0;
    font-size : 11px;
    height : 30px;
    line-height : 30px;
    border-radius : 2px;
    transition : width 1s ease-in-out;
    }
    .elementor-progress-text {
    flex-grow : 1;
    white-space : nowrap;
    text-overflow : ellipsis;
    overflow : hidden;
    padding-left : 15px;
    }
    .elementor-progress-percentage {
    padding-right : 15px;
    }
    .elementor-widget-progress .elementor-progress-wrapper.progress-info .elementor-progress-bar {
    background-color : #5bc0de;
    }
    .elementor-widget-progress .elementor-progress-wrapper.progress-success .elementor-progress-bar {
    background-color : #5cb85c;
    }
    .elementor-widget-progress .elementor-progress-wrapper.progress-warning .elementor-progress-bar {
    background-color : #f0ad4e;
    }
    .elementor-widget-progress .elementor-progress-wrapper.progress-danger .elementor-progress-bar {
    background-color : #d9534f;
    }
    .elementor-progress .elementor-title {
    display : block;
    }
    @media (max-width: 767px) {
    .elementor-progress-text {
    padding-left : 10px;
    }
    }
    .e-con-inner .elementor-progress-wrapper, .e-con .elementor-progress-wrapper {
    height : auto;
    }
    .elementor-widget-social-icons.elementor-grid-0 .elementor-widget-container, .elementor-widget-social-icons.elementor-grid-mobile-0 .elementor-widget-container, .elementor-widget-social-icons.elementor-grid-tablet-0 .elementor-widget-container {
    line-height : 1;
    font-size : 0;
    }
    .elementor-widget-social-icons:not(.elementor-grid-0):not(.elementor-grid-tablet-0):not(.elementor-grid-mobile-0) .elementor-grid {
    display : inline-grid;
    }
    .elementor-widget-social-icons .elementor-grid {
    column-gap : var(--grid-column-gap, 5px);
    row-gap : var(--grid-row-gap, 5px);
    justify-content : var(--justify-content, center);
    justify-items : var(--justify-content, center);
    }
    .elementor-icon.elementor-social-icon {
    font-size : var(--icon-size, 25px);
    line-height : var(--icon-size, 25px);
    width : calc(var(--icon-size, 25px) + (2 * var(--icon-padding, 0.5em)));
    height : calc(var(--icon-size, 25px) + (2 * var(--icon-padding, 0.5em)));
    }
    .elementor-social-icon {
    --e-social-icon-icon-color : #fff;
    display : inline-flex;
    background-color : #69727d;
    align-items : center;
    justify-content : center;
    text-align : center;
    cursor : pointer;
    }
    .elementor-social-icon i {
    color : var(--e-social-icon-icon-color);
    }
    .elementor-social-icon svg {
    fill : var(--e-social-icon-icon-color);
    }
    .elementor-social-icon:last-child {
    margin : 0;
    }
    .elementor-social-icon:hover {
    opacity : 0.9;
    color : #fff;
    }
    .elementor-social-icon-android {
    background-color : #a4c639;
    }
    .elementor-social-icon-apple {
    background-color : #999;
    }
    .elementor-social-icon-behance {
    background-color : #1769ff;
    }
    .elementor-social-icon-bitbucket {
    background-color : #205081;
    }
    .elementor-social-icon-codepen {
    background-color : #000;
    }
    .elementor-social-icon-delicious {
    background-color : #39f;
    }
    .elementor-social-icon-deviantart {
    background-color : #05cc47;
    }
    .elementor-social-icon-digg {
    background-color : #005be2;
    }
    .elementor-social-icon-dribbble {
    background-color : #ea4c89;
    }
    .elementor-social-icon-elementor {
    background-color : #d30c5c;
    }
    .elementor-social-icon-envelope {
    background-color : #ea4335;
    }
    .elementor-social-icon-facebook, .elementor-social-icon-facebook-f {
    background-color : #3b5998;
    }
    .elementor-social-icon-flickr {
    background-color : #0063dc;
    }
    .elementor-social-icon-foursquare {
    background-color : #2d5be3;
    }
    .elementor-social-icon-free-code-camp, .elementor-social-icon-freecodecamp {
    background-color : #006400;
    }
    .elementor-social-icon-github {
    background-color : #333;
    }
    .elementor-social-icon-gitlab {
    background-color : #e24329;
    }
    .elementor-social-icon-globe {
    background-color : #69727d;
    }
    .elementor-social-icon-google-plus, .elementor-social-icon-google-plus-g {
    background-color : #dd4b39;
    }
    .elementor-social-icon-houzz {
    background-color : #7ac142;
    }
    .elementor-social-icon-instagram {
    background-color : #262626;
    }
    .elementor-social-icon-jsfiddle {
    background-color : #487aa2;
    }
    .elementor-social-icon-link {
    background-color : #818a91;
    }
    .elementor-social-icon-linkedin, .elementor-social-icon-linkedin-in {
    background-color : #0077b5;
    }
    .elementor-social-icon-medium {
    background-color : #00ab6b;
    }
    .elementor-social-icon-meetup {
    background-color : #ec1c40;
    }
    .elementor-social-icon-mixcloud {
    background-color : #273a4b;
    }
    .elementor-social-icon-odnoklassniki {
    background-color : #f4731c;
    }
    .elementor-social-icon-pinterest {
    background-color : #bd081c;
    }
    .elementor-social-icon-product-hunt {
    background-color : #da552f;
    }
    .elementor-social-icon-reddit {
    background-color : #ff4500;
    }
    .elementor-social-icon-rss {
    background-color : #f26522;
    }
    .elementor-social-icon-shopping-cart {
    background-color : #4caf50;
    }
    .elementor-social-icon-skype {
    background-color : #00aff0;
    }
    .elementor-social-icon-slideshare {
    background-color : #0077b5;
    }
    .elementor-social-icon-snapchat {
    background-color : #fffc00;
    }
    .elementor-social-icon-soundcloud {
    background-color : #f80;
    }
    .elementor-social-icon-spotify {
    background-color : #2ebd59;
    }
    .elementor-social-icon-stack-overflow {
    background-color : #fe7a15;
    }
    .elementor-social-icon-steam {
    background-color : #00adee;
    }
    .elementor-social-icon-stumbleupon {
    background-color : #eb4924;
    }
    .elementor-social-icon-telegram {
    background-color : #2ca5e0;
    }
    .elementor-social-icon-thumb-tack {
    background-color : #1aa1d8;
    }
    .elementor-social-icon-tripadvisor {
    background-color : #589442;
    }
    .elementor-social-icon-tumblr {
    background-color : #35465c;
    }
    .elementor-social-icon-twitch {
    background-color : #6441a5;
    }
    .elementor-social-icon-twitter {
    background-color : #1da1f2;
    }
    .elementor-social-icon-viber {
    background-color : #665cac;
    }
    .elementor-social-icon-vimeo {
    background-color : #1ab7ea;
    }
    .elementor-social-icon-vk {
    background-color : #45668e;
    }
    .elementor-social-icon-weibo {
    background-color : #dd2430;
    }
    .elementor-social-icon-weixin {
    background-color : #31a918;
    }
    .elementor-social-icon-whatsapp {
    background-color : #25d366;
    }
    .elementor-social-icon-wordpress {
    background-color : #21759b;
    }
    .elementor-social-icon-xing {
    background-color : #026466;
    }
    .elementor-social-icon-yelp {
    background-color : #af0606;
    }
    .elementor-social-icon-youtube {
    background-color : #cd201f;
    }
    .elementor-social-icon-500px {
    background-color : #0099e5;
    }
    .elementor-shape-rounded .elementor-icon.elementor-social-icon {
    border-radius : 10%;
    }
    .elementor-shape-circle .elementor-icon.elementor-social-icon {
    border-radius : 50%;
    }
    .e-con {
    --container-widget-width : 100%;
    }
    .e-con-inner > .elementor-widget-spacer, .e-con > .elementor-widget-spacer {
    --align-self : var(--container-widget-align-self, initial);
    --flex-shrink : 0;
    }
    .e-con-inner > .elementor-widget-spacer > .elementor-widget-container, .e-con-inner > .elementor-widget-spacer > .elementor-widget-container > .elementor-spacer, .e-con > .elementor-widget-spacer > .elementor-widget-container, .e-con > .elementor-widget-spacer > .elementor-widget-container > .elementor-spacer {
    height : 100%;
    }
    .elementor-star-rating {
    color : #ccd6df;
    font-family : eicons;
    display : inline-block;
    }
    .elementor-star-rating i {
    display : inline-block;
    position : relative;
    font-style : normal;
    cursor : default;
    }
    .elementor-star-rating i:before {
    content : "\e934";
    display : block;
    font-size : inherit;
    font-family : inherit;
    position : absolute;
    overflow : hidden;
    color : #f0ad4e;
    top : 0;
    left : 0;
    }
    .elementor-star-rating .elementor-star-empty:before {
    content : none;
    }
    .elementor-star-rating .elementor-star-1:before {
    width : 10%;
    }
    .elementor-star-rating .elementor-star-2:before {
    width : 20%;
    }
    .elementor-star-rating .elementor-star-3:before {
    width : 30%;
    }
    .elementor-star-rating .elementor-star-4:before {
    width : 40%;
    }
    .elementor-star-rating .elementor-star-5:before {
    width : 50%;
    }
    .elementor-star-rating .elementor-star-6:before {
    width : 60%;
    }
    .elementor-star-rating .elementor-star-7:before {
    width : 70%;
    }
    .elementor-star-rating .elementor-star-8:before {
    width : 80%;
    }
    .elementor-star-rating .elementor-star-9:before {
    width : 90%;
    }
    .elementor-star-rating__wrapper {
    display : flex;
    align-items : center;
    }
    .elementor-star-rating__title {
    margin-right : 10px;
    }
    .elementor-star-rating--align-right .elementor-star-rating__wrapper {
    text-align : right;
    justify-content : flex-end;
    }
    .elementor-star-rating--align-left .elementor-star-rating__wrapper {
    text-align : left;
    justify-content : flex-start;
    }
    .elementor-star-rating--align-center .elementor-star-rating__wrapper {
    text-align : center;
    justify-content : center;
    }
    .elementor-star-rating--align-justify .elementor-star-rating__title {
    margin-right : auto;
    }
    @media (max-width: 1024px) {
    .elementor-star-rating-tablet--align-right .elementor-star-rating__wrapper {
    text-align : right;
    justify-content : flex-end;
    }
    .elementor-star-rating-tablet--align-left .elementor-star-rating__wrapper {
    text-align : left;
    justify-content : flex-start;
    }
    .elementor-star-rating-tablet--align-center .elementor-star-rating__wrapper {
    text-align : center;
    justify-content : center;
    }
    .elementor-star-rating-tablet--align-justify .elementor-star-rating__title {
    margin-right : auto;
    }
    }
    @media (max-width: 767px) {
    .elementor-star-rating-mobile--align-right .elementor-star-rating__wrapper {
    text-align : right;
    justify-content : flex-end;
    }
    .elementor-star-rating-mobile--align-left .elementor-star-rating__wrapper {
    text-align : left;
    justify-content : flex-start;
    }
    .elementor-star-rating-mobile--align-center .elementor-star-rating__wrapper {
    text-align : center;
    justify-content : center;
    }
    .elementor-star-rating-mobile--align-justify .elementor-star-rating__title {
    margin-right : auto;
    }
    }
    .last-star {
    letter-spacing : 0;
    }
    .elementor--star-style-star_unicode .elementor-star-rating {
    font-family : Arial, Helvetica, sans-serif;
    }
    .elementor--star-style-star_unicode .elementor-star-rating i:not(.elementor-star-empty):before {
    content : "â";
    }
    .elementor-widget-tabs.elementor-tabs-view-vertical .elementor-tabs-wrapper {
    width : 25%;
    flex-shrink : 0;
    }
    .elementor-widget-tabs.elementor-tabs-view-vertical .elementor-tab-desktop-title.elementor-active {
    border-right-style : none;
    }
    .elementor-widget-tabs.elementor-tabs-view-vertical .elementor-tab-desktop-title.elementor-active:after, .elementor-widget-tabs.elementor-tabs-view-vertical .elementor-tab-desktop-title.elementor-active:before {
    height : 999em;
    width : 0;
    right : 0;
    border-right-style : solid;
    }
    .elementor-widget-tabs.elementor-tabs-view-vertical .elementor-tab-desktop-title.elementor-active:before {
    top : 0;
    transform : translateY(-100%);
    }
    .elementor-widget-tabs.elementor-tabs-view-vertical .elementor-tab-desktop-title.elementor-active:after {
    top : 100%;
    }
    .elementor-widget-tabs.elementor-tabs-view-horizontal .elementor-tab-desktop-title {
    display : table-cell;
    }
    .elementor-widget-tabs.elementor-tabs-view-horizontal .elementor-tab-desktop-title.elementor-active {
    border-bottom-style : none;
    }
    .elementor-widget-tabs.elementor-tabs-view-horizontal .elementor-tab-desktop-title.elementor-active:after, .elementor-widget-tabs.elementor-tabs-view-horizontal .elementor-tab-desktop-title.elementor-active:before {
    bottom : 0;
    height : 0;
    width : 999em;
    border-bottom-style : solid;
    }
    .elementor-widget-tabs.elementor-tabs-view-horizontal .elementor-tab-desktop-title.elementor-active:before {
    right : 100%;
    }
    .elementor-widget-tabs.elementor-tabs-view-horizontal .elementor-tab-desktop-title.elementor-active:after {
    left : 100%;
    }
    .elementor-widget-tabs .elementor-tab-content, .elementor-widget-tabs .elementor-tab-title, .elementor-widget-tabs .elementor-tab-title:after, .elementor-widget-tabs .elementor-tab-title:before, .elementor-widget-tabs .elementor-tabs-content-wrapper {
    border : #d5d8dc 1px;
    }
    .elementor-widget-tabs .elementor-tabs {
    text-align : left;
    }
    .elementor-widget-tabs .elementor-tabs-wrapper {
    overflow : hidden;
    }
    .elementor-widget-tabs .elementor-tab-title {
    cursor : pointer;
    outline : var(--focus-outline, none);
    }
    .elementor-widget-tabs .elementor-tab-desktop-title {
    position : relative;
    padding : 20px 25px;
    font-weight : 700;
    line-height : 1;
    border : transparent solid;
    }
    .elementor-widget-tabs .elementor-tab-desktop-title.elementor-active {
    border-color : #d5d8dc;
    }
    .elementor-widget-tabs .elementor-tab-desktop-title.elementor-active:after, .elementor-widget-tabs .elementor-tab-desktop-title.elementor-active:before {
    display : block;
    content : "";
    position : absolute;
    }
    .elementor-widget-tabs .elementor-tab-desktop-title:focus-visible {
    border : #000 solid 1px;
    }
    .elementor-widget-tabs .elementor-tab-mobile-title {
    padding : 10px;
    cursor : pointer;
    }
    .elementor-widget-tabs .elementor-tab-content {
    padding : 20px;
    display : none;
    }
    @media (max-width: 767px) {
    .elementor-tabs .elementor-tab-content, .elementor-tabs .elementor-tab-title {
    border-style : solid solid none;
    }
    .elementor-tabs .elementor-tabs-wrapper {
    display : none;
    }
    .elementor-tabs .elementor-tabs-content-wrapper {
    border-bottom-style : solid;
    }
    .elementor-tabs .elementor-tab-content {
    padding : 10px;
    }
    }
    @media (min-width: 768px) {
    .elementor-widget-tabs.elementor-tabs-view-vertical .elementor-tabs {
    display : flex;
    }
    .elementor-widget-tabs.elementor-tabs-view-vertical .elementor-tabs-wrapper {
    flex-direction : column;
    }
    .elementor-widget-tabs.elementor-tabs-view-vertical .elementor-tabs-content-wrapper {
    flex-grow : 1;
    border-style : solid solid solid none;
    }
    .elementor-widget-tabs.elementor-tabs-view-horizontal .elementor-tab-content {
    border-style : none solid solid;
    }
    .elementor-widget-tabs.elementor-tabs-alignment-center .elementor-tabs-wrapper, .elementor-widget-tabs.elementor-tabs-alignment-end .elementor-tabs-wrapper, .elementor-widget-tabs.elementor-tabs-alignment-stretch .elementor-tabs-wrapper {
    display : flex;
    }
    .elementor-widget-tabs.elementor-tabs-alignment-center .elementor-tabs-wrapper {
    justify-content : center;
    }
    .elementor-widget-tabs.elementor-tabs-alignment-end .elementor-tabs-wrapper {
    justify-content : flex-end;
    }
    .elementor-widget-tabs.elementor-tabs-alignment-stretch.elementor-tabs-view-horizontal .elementor-tab-title {
    width : 100%;
    }
    .elementor-widget-tabs.elementor-tabs-alignment-stretch.elementor-tabs-view-vertical .elementor-tab-title {
    height : 100%;
    }
    .elementor-tabs .elementor-tab-mobile-title {
    display : none;
    }
    }
    .elementor-testimonial-wrapper {
    overflow : hidden;
    text-align : center;
    }
    .elementor-testimonial-wrapper .elementor-testimonial-content {
    font-size : 1.3em;
    margin-bottom : 20px;
    }
    .elementor-testimonial-wrapper .elementor-testimonial-name {
    line-height : 1.5;
    display : block;
    }
    .elementor-testimonial-wrapper .elementor-testimonial-job {
    font-size : 0.85em;
    display : block;
    }
    .elementor-testimonial-wrapper .elementor-testimonial-meta {
    width : 100%;
    line-height : 1;
    }
    .elementor-testimonial-wrapper .elementor-testimonial-meta-inner {
    display : inline-block;
    }
    .elementor-testimonial-wrapper .elementor-testimonial-meta .elementor-testimonial-details, .elementor-testimonial-wrapper .elementor-testimonial-meta .elementor-testimonial-image {
    display : table-cell;
    vertical-align : middle;
    }
    .elementor-testimonial-wrapper .elementor-testimonial-meta .elementor-testimonial-image img {
    width : 60px;
    height : 60px;
    border-radius : 50%;
    object-fit : cover;
    max-width : none;
    }
    .elementor-testimonial-wrapper .elementor-testimonial-meta.elementor-testimonial-image-position-aside .elementor-testimonial-image {
    padding-right : 15px;
    }
    .elementor-testimonial-wrapper .elementor-testimonial-meta.elementor-testimonial-image-position-aside .elementor-testimonial-details {
    text-align : left;
    }
    .elementor-testimonial-wrapper .elementor-testimonial-meta.elementor-testimonial-image-position-top .elementor-testimonial-details, .elementor-testimonial-wrapper .elementor-testimonial-meta.elementor-testimonial-image-position-top .elementor-testimonial-image {
    display : block;
    }
    .elementor-testimonial-wrapper .elementor-testimonial-meta.elementor-testimonial-image-position-top .elementor-testimonial-image {
    margin-bottom : 20px;
    }
    .elementor-widget-text-editor.elementor-drop-cap-view-stacked .elementor-drop-cap {
    background-color : #69727d;
    color : #fff;
    }
    .elementor-widget-text-editor.elementor-drop-cap-view-framed .elementor-drop-cap {
    color : #69727d;
    border : solid 3px;
    background-color : transparent;
    }
    .elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap {
    margin-top : 8px;
    }
    .elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap-letter {
    width : 1em;
    height : 1em;
    }
    .elementor-widget-text-editor .elementor-drop-cap {
    float : left;
    text-align : center;
    line-height : 1;
    font-size : 50px;
    }
    .elementor-widget-text-editor .elementor-drop-cap-letter {
    display : inline-block;
    }
    .elementor-toggle {
    text-align : left;
    }
    .elementor-toggle .elementor-tab-title {
    font-weight : 700;
    line-height : 1;
    margin : 0;
    padding : 15px;
    border-bottom : 1px solid #d5d8dc;
    cursor : pointer;
    outline : none;
    }
    .elementor-toggle .elementor-tab-title .elementor-toggle-icon {
    display : inline-block;
    width : 1em;
    }
    .elementor-toggle .elementor-tab-title .elementor-toggle-icon svg {
    margin-inline-start : -5px;
    width : 1em;
    height : 1em;
    }
    .elementor-toggle .elementor-tab-title .elementor-toggle-icon.elementor-toggle-icon-right {
    float : right;
    text-align : right;
    }
    .elementor-toggle .elementor-tab-title .elementor-toggle-icon.elementor-toggle-icon-left {
    float : left;
    text-align : left;
    }
    .elementor-toggle .elementor-tab-title .elementor-toggle-icon .elementor-toggle-icon-closed {
    display : block;
    }
    .elementor-toggle .elementor-tab-title .elementor-toggle-icon .elementor-toggle-icon-opened {
    display : none;
    }
    .elementor-toggle .elementor-tab-title.elementor-active {
    border-bottom : none;
    }
    .elementor-toggle .elementor-tab-title.elementor-active .elementor-toggle-icon-closed {
    display : none;
    }
    .elementor-toggle .elementor-tab-title.elementor-active .elementor-toggle-icon-opened {
    display : block;
    }
    .elementor-toggle .elementor-tab-content {
    padding : 15px;
    border-bottom : 1px solid #d5d8dc;
    display : none;
    }
    @media (max-width: 767px) {
    .elementor-toggle .elementor-tab-title {
    padding : 12px;
    }
    .elementor-toggle .elementor-tab-content {
    padding : 12px 10px;
    }
    }
    .e-con-inner > .elementor-widget-toggle, .e-con > .elementor-widget-toggle {
    width : var(--container-widget-width);
    --flex-grow : var(--container-widget-flex-grow);
    }
    .elementor-widget-video .elementor-widget-container {
    overflow : hidden;
    transform : translateZ(0);
    }
    .elementor-widget-video .elementor-wrapper iframe, .elementor-widget-video .elementor-wrapper video {
    height : 100%;
    width : 100%;
    display : flex;
    border : none;
    background-color : #000;
    }
    .elementor-widget-video .elementor-open-inline .elementor-custom-embed-image-overlay {
    position : absolute;
    top : 0;
    left : 0;
    width : 100%;
    height : 100%;
    background-size : cover;
    background-position : 50%;
    }
    .elementor-widget-video .elementor-custom-embed-image-overlay {
    cursor : pointer;
    text-align : center;
    }
    .elementor-widget-video .elementor-custom-embed-image-overlay:hover .elementor-custom-embed-play i {
    opacity : 1;
    }
    .elementor-widget-video .elementor-custom-embed-image-overlay img {
    display : block;
    width : 100%;
    }
    .elementor-widget-video .e-hosted-video .elementor-video {
    object-fit : cover;
    }
    .e-con-inner > .elementor-widget-video, .e-con > .elementor-widget-video {
    width : var(--container-widget-width);
    --flex-grow : var(--container-widget-flex-grow);
    }
    