.login-card {
    max-width     : 500px;
    margin    : 0 auto;
    text-align: center;
    margin-top: 10%;
}

.centered {
    margin: 0 auto;
}

.MuiSvgIcon-root.Mui-active{
    color: #28a8e0 !important;
}

.MuiSvgIcon-root.Mui-completed{
    color: #7db642 !important;
}

.btn-direction{
    background-color: #28a8e0 !important;
    color: #fff !important;
}
.btn-direction:hover {
    background-color: #7db642 !important;
    color: #fff !important;
}

.btn-started{
    background-color: #28a8e0 !important;
    color: #fff !important;
}
.btn-started:hover {
    background-color: #7db642 !important;
    color: #fff !important;
}

.btn-privacy-policy{
    background-color: #fff !important;
    color: #28a8e0 !important;
}


.font-sans,
.font-sans button ,
.font-sans input{
    font-family: sans-serif !important;
}


.oswald {
    font-family: 'Oswald' !important;
}

.copy-right {
    border: solid 3px #000;
    border-radius: 10px;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 2px;
    font-size: 15px;    
}

.mobil-single-select{
    display: block !important;

    button {
        width: 100%;
    }
}


.assessment-background{
    min-height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('./assets/images/ipad.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}