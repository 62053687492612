@tailwind base;
@tailwind components;
@tailwind utilities;

.text-white {
    color: white !important;
}

.bg-blue-500 {
    background-color: navy !important;
}

