.elementor-22015 .elementor-element.elementor-element-2ba4a3d {
    transition : background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding : 30px 0 20px 0;
    }
    .elementor-element, .elementor-lightbox {
    --swiper-theme-color : #000;
    --swiper-navigation-size : 44px;
    --swiper-pagination-bullet-size : 6px;
    --swiper-pagination-bullet-horizontal-gap : 6px;
    }
    .elementor-section {
    position : relative;
    }
    .elementor-element {
    --flex-direction : initial;
    --flex-wrap : initial;
    --justify-content : initial;
    --align-items : initial;
    --align-content : initial;
    --gap : initial;
    --flex-basis : initial;
    --flex-grow : initial;
    --flex-shrink : initial;
    --order : initial;
    --align-self : initial;
    }
    .elementor *, .elementor :after, .elementor :before {
    box-sizing : border-box;
    }
    .elementor-section.elementor-section-boxed > .elementor-container {
    max-width : 1140px;
    }
    .elementor-section .elementor-container {
    display : flex;
    margin-right : auto;
    margin-left : auto;
    position : relative;
    }
    .elementor *, .elementor :after, .elementor :before {
    box-sizing : border-box;
    }
    .elementor-element {
    --flex-direction : initial;
    --flex-wrap : initial;
    --justify-content : initial;
    --align-items : initial;
    --align-content : initial;
    --gap : initial;
    --flex-basis : initial;
    --flex-grow : initial;
    --flex-shrink : initial;
    --order : initial;
    --align-self : initial;
    flex-basis : var(--flex-basis);
    flex-grow : var(--flex-grow);
    flex-shrink : var(--flex-shrink);
    order : var(--order);
    align-self : var(--align-self);
    }
    .elementor-row {
    width : 100%;
    display : flex;
    }
    .elementor *, .elementor :after, .elementor :before {
    box-sizing : border-box;
    }
    .elementor-element {
    --flex-direction : initial;
    --flex-wrap : initial;
    --justify-content : initial;
    --align-items : initial;
    --align-content : initial;
    --gap : initial;
    --flex-basis : initial;
    --flex-grow : initial;
    --flex-shrink : initial;
    --order : initial;
    --align-self : initial;
    flex-basis : var(--flex-basis);
    flex-grow : var(--flex-grow);
    flex-shrink : var(--flex-shrink);
    order : var(--order);
    align-self : var(--align-self);
    }
    .elementor-22015 .elementor-element.elementor-element-d38b935 > .elementor-widget-container {
    margin : 3% 0% 3% 0%;
    }
    .elementor-element .elementor-widget-container {
    transition : background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s, transform var(--e-transform-transition-duration, 0.4s);
    }
    .elementor *, .elementor :after, .elementor :before {
    box-sizing : border-box;
    }
    .elementor-element, .elementor-lightbox {
    --swiper-theme-color : #000;
    --swiper-navigation-size : 44px;
    --swiper-pagination-bullet-size : 6px;
    --swiper-pagination-bullet-horizontal-gap : 6px;
    }
    .elementor-section .elementor-container {
    display : flex;
    margin-right : auto;
    margin-left : auto;
    position : relative;
    }
    .meter {
    height : 20px !important;
    width : 100% !important;
    background : linear-gradient(to right, red 0%, orange 30%, yellow 60%, yellow 70%, green 100%) !important;
    position : relative !important;
    }
    .meter-value {
    height : 100%;
    background-color : blue;
    position : absolute;
    left : 0;
    top : 0;
    }
    .meter::after {
    content : "";
    position : absolute;
    border : transparent solid 15px;
    border-bottom : 15px solid black;
    left : calc(var(--value, 0%) - 15px);
    transform : scaleY(-1);
    }
    .meter-label {
    position : absolute;
    font-family : "Open Sans";
    font-size : calc(14px + 0.4vw);
    font-weight : bold;
    text-align : center;
    }
    .meter-label-left {
    left : 0;
    top : -35px;
    font-size : calc(14px + 0.4vw) !important ;
    }
    .meter-label-right {
    right : 0;
    top : -35px;
    font-size : calc(14px + 0.4vw) !important ;
    }
    .meter-label-bottom {
    position : absolute;
    font-family : "Open Sans";
    font-size : calc(14px + 0.4vw);
    font-weight : bold;
    text-align : center;
    }
    .meter-label-bottom-left {
    left : 0;
    bottom : -35px;
    font-size : calc(14px + 0.4vw) !important ;
    }
    .meter-label-bottom-right {
    right : 0;
    bottom : -35px;
    font-size : calc(14px + 0.4vw) !important ;
    }
    .meter2 {
    height : 20px;
    width : 100%;
    background : linear-gradient(to right, red 0%, orange 30%, yellow 60%, yellow 70%, green 100%);
    position : relative;
    }
    .meter2::after {
    content : "";
    position : absolute;
    border : transparent solid 15px;
    border-bottom : 15px solid black;
    left : calc(var(--value2, 0%) - 15px);
    transform : scaleY(-1);
    }
    .meter3 {
    height : 20px;
    width : 100%;
    background : linear-gradient(to right, red 0%, orange 30%, yellow 60%, yellow 70%, green 100%);
    position : relative;
    }
    .meter3::after {
    content : "";
    position : absolute;
    border : transparent solid 15px;
    border-bottom : 15px solid black;
    left : calc(var(--value3, 0%) - 15px);
    transform : scaleY(-1);
    }
    .meter4 {
    height : 20px !important ;
    width : 100% !important ;
    background : linear-gradient(to right, red 0%, orange 30%, yellow 60%, yellow 70%, green 100%) !important ;
    position : relative !important ;
    }
    .meter4::after {
    content : "";
    position : absolute;
    border : transparent solid 15px;
    border-bottom : 15px solid black;
    left : calc(var(--value4, 0%) - 15px);
    transform : scaleY(-1);
    }

    #flagTextDiv{
        font-family: "Open Sans";
        font-size: calc(14px + 0.5vw);
        text-align: center;
        margin-bottom: -20px;
        margin-top: 2%;
        font-weight: bold;
        color: rgb(40, 167, 223);
    }

    #subHeadTextDiv {
    font-family: "Open Sans";
    text-transform: uppercase;
    font-size: calc(28px + 0.5vw);
    text-align: center;
    font-weight: bold;
    color: rgb(40, 167, 223);
    line-height: 1.3;
    }

    #scoreTextDiv {
    font-family: "Open Sans";
    text-transform: uppercase;
    font-size: calc(22px + 0.5vw);
    text-align: center;
    }

    #selfCareScoreTextDiv {
        font-family: "Open Sans";
        font-size: calc(16px + 0.5vw);
        text-align: center;
        margin-bottom: -20px;
    }

    #languageScoreTextDiv {
        font-family: "Open Sans";
        font-size: calc(16px + 0.5vw);
        text-align: center;
        margin-bottom: -20px;
    }

    #problemScoreTextDiv {
        font-family: "Open Sans";
        font-size: calc(16px + 0.5vw);
        text-align: center;
        margin-bottom: -20px;
    }

   
   .secondPage {
        width : 80%;
        margin : 0 auto;
     }

    .page {
        height: 100%;
    }

